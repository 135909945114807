import React, { useEffect, useState } from "react";
import { getCookieValue } from "../../services/getCookie";
import { sifreCoz } from "../../services/getPass";
import { db } from "../../firebase";
import {
  collection,
  getDocs,
  query,
  where,
  updateDoc,
  doc,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const Duyurular = () => {
  const [duyurular, setDuyurular] = useState([]);
  const [firmaKodu, setFirmaKodu] = useState("");
  const [cozulmus, setCozulmus] = useState({});
  const [duyuruDurum, setDuyuruDurum] = useState(true);
  const token = getCookieValue("token");
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      tokenKontrol(token);
    } else {
      navigate("/");
    }
  }, [token]);

  useEffect(() => {
    const getDuyurular = async () => {
      try {
        const mRef = collection(db, "menu");
        const qM = query(mRef, where("firmaKod", "==", firmaKodu));
        const getM = await getDocs(qM);
        if (!getM.empty) {
          const getData = getM.docs[0].data();
          getData.id = getM.docs[0].id;
          setCozulmus(getData);
          setDuyurular(getData.duyuru || []);
          setDuyuruDurum(getData.duyuruDurum || true);
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (firmaKodu) {
      getDuyurular();
    }
  }, [firmaKodu]);

  const tokenKontrol = (gelenToken) => {
    const cozulmusVeri = sifreCoz(gelenToken);
    if (cozulmusVeri.firmaKod) {
      setFirmaKodu(cozulmusVeri.firmaKod);
    } else {
      navigate("/");
    }
  };

  const handleInputChange = (index, field, value) => {
    const updatedDuyurular = [...duyurular];
    updatedDuyurular[index] = { ...updatedDuyurular[index], [field]: value };
    setDuyurular(updatedDuyurular);
  };

  const handleSave = async () => {
    try {
      const docRef = doc(db, "menu", cozulmus.id);
      await updateDoc(docRef, { duyuru: duyurular });
      toast.success("Duyurular başarıyla güncellendi!");
    } catch (error) {
      console.log("Error updating document: ", error);
      toast.error("Duyurular güncellenirken bir hata oluştu.");
    }
  };

  const handleRemove = async (index) => {
    try {
      const updatedDuyurular = [...duyurular];
      updatedDuyurular.splice(index, 1);

      const docRef = doc(db, "menu", cozulmus.id);
      await updateDoc(docRef, { duyuru: updatedDuyurular });

      setDuyurular(updatedDuyurular);
      toast.success("Duyuru başarıyla kaldırıldı.");
    } catch (error) {
      console.log("Error removing document: ", error);
      toast.error("Duyuru kaldırılırken bir hata oluştu.");
    }
  };

  const handleAddDuyuru = () => {
    const newDuyuru = { baslik: "", metin: "" };
    setDuyurular([...duyurular, newDuyuru]);
    toast.success(
      "Duyuru listeye eklendi. Lütfen gerekli alanları doldurunuz."
    );
  };

  const handleGoster = async () => {
    try {
      const docRef = doc(db, "menu", cozulmus.id);
      await updateDoc(docRef, { duyuruDurum: true });
      toast.success("Duyurular aktif hale getirildi.");
    } catch (error) {
      toast.error("Hata var.");
      console.log("Error updating document: ", error);
    }
  };

  const handleGizle = async () => {
    try {
      const docRef = doc(db, "menu", cozulmus.id);
      await updateDoc(docRef, { duyuruDurum: false });
      toast.success("Duyurular pasif hale getirildi.");
    } catch (error) {
      toast.error("Hata var.");
      console.log("Error", error);
    }
  };

  return (
    <div className="min-h-screen">
      <div>
        <img
          src={cozulmus.resimURL}
          className="w-40 pt-2 mx-auto"
          alt="Firma Resmi"
        />
        <p className="text-center text-4xl font-bold">{cozulmus.firmaAd}</p>
        <p className="text-xl mt-1 font-light text-center">DUYURULAR</p>
        <p className="py-2 bg-orange-300 bg-opacity-25 text-sm text-center w-full max-w-sm md:max-w-xl mx-auto border rounded-lg mt-2">
          Lütfen İşlemleri Yaptıktan Sonra
          <span className="font-semibold"> Kaydet </span> Düğmesine Tıklayalım.
        </p>
        <div className="flex mt-4 items-center justify-center px-2 md:px-0 gap-2 pb-2 flex-col">
          {duyurular.length > 0 ? (
            duyurular.map((duyuru, index) => (
              <div
                key={index}
                className="p-2 bg-neutral-100 flex items-center flex-col gap-1 justify-center md:w-2/6 w-full rounded-xl"
              >
                <div className="flex items-center gap-3 w-full justify-between">
                  <p>Başlık</p>
                  <input
                    type="text"
                    className="px-2 py-2 rounded-xl border placeholder-black text-lg max-w-lg w-full"
                    value={duyuru.baslik}
                    onChange={(e) =>
                      handleInputChange(index, "baslik", e.target.value)
                    }
                  />
                </div>
                <div className="flex items-center gap-3 w-full justify-between">
                  <p>Metin</p>
                  <textarea
                    type="text"
                    className="px-2 py-2 rounded-xl border placeholder-black text-lg max-w-lg w-full"
                    value={duyuru.metin}
                    onChange={(e) =>
                      handleInputChange(index, "metin", e.target.value)
                    }
                  />
                </div>
                <button
                  onClick={() => handleRemove(index)}
                  className="w-full py-1 text-white text-lg font-bold active:scale-105 hover:bg-red-600 bg-red-500 rounded-xl"
                >
                  Kaldır
                </button>
              </div>
            ))
          ) : (
            <p className="text-center text-xl font-bold">Duyuru bulunamadı.</p>
          )}
        </div>
        <div className="flex gap-2 items-center mb-5 mt-5 flex-col lg:bottom-5 lg:fixed lg:transform lg:right-10 lg:translate-x-0">
          <button
            onClick={handleAddDuyuru}
            className="w-60 lg:w-full text-white py-2 bg-blue-400 rounded-xl text-lg font-bold"
          >
            Oluştur
          </button>
          <div className="flex items-center gap-2">
            <button
              onClick={handleGoster}
              className={`md:w-40 w-28 bg-green-500 text-white py-2  rounded-xl text-lg font-bold`}
            >
              Göster
            </button>
            <button
              onClick={handleGizle}
              className={`md:w-40 w-28 bg-red-500 text-white py-2  rounded-xl text-lg font-bold`}
            >
              Gizle
            </button>
          </div>
          <div className="flex items-center gap-2">
            <button
              onClick={() => navigate("/bayi/panel")}
              className="w-28 md:w-40 text-white py-2 bg-orange-500 rounded-xl text-lg font-bold"
            >
              Anasayfa
            </button>
            <button
              onClick={handleSave}
              className="w-28 md:w-40 text-white py-2 bg-green-400 rounded-xl text-lg font-bold"
            >
              Kaydet
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Duyurular;
