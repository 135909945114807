import React, { useState } from "react";
import logo from "../../assets/img/logo.png";
import { useNavigate } from "react-router-dom";
import KayitModal from "../../modals/landing/KayitOl";

const Header = () => {
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="py-3 font-manrope flex items-center justify-between md:px-52 px-2 shadow-xl">
      <img src={logo} alt="minimenü" className="w-32 p-2 bg-black rounded-xl" />
      <div className="flex flex-row gap-3">
        <button
          onClick={() => {
            navigate("/bayi/giris");
          }}
          className="py-3 w-40 rounded-xl text-black font-bold hover:scale-105"
        >
          Giriş Yap
        </button>
        <button
          onClick={handleOpenModal}
          className="py-3 w-40 bg-red-500 rounded-xl text-white font-bold hover:scale-105 hidden md:block"
        >
          Kayıt Ol
        </button>
      </div>
      {isModalOpen && <KayitModal onClose={handleCloseModal} />}
    </div>
  );
};

export default Header;
