import React, { useState } from "react";
import { db } from "../../../firebase";
import {
  collection,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import toast from "react-hot-toast";

const FirmaIsim = ({ firmaK }) => {
  const [sifre, setSifre] = useState("");

  const handleDegistir = async (e) => {
    e.preventDefault();
    try {
      const menuRef = collection(db, "menu");
      const mQ = query(menuRef, where("firmaKod", "==", firmaK));
      const getDoc = await getDocs(mQ);

      if (sifre == "") {
        toast.error("Lütfen Şifre giriniz.");
      } else {
        getDoc.forEach(async (donenDoc) => {
          const docRef = donenDoc.ref;
          await updateDoc(docRef, {
            sifre,
          });
        });

        toast.success("Şifre değiştirildi.");
      }
    } catch (error) {
      toast.error("Sistemde hata oluştu.");
      console.log(error);
    }
  };

  return (
    <div className="fixed font-manrope bg-neutral-800 bg-opacity-70 overflow-y-auto inset-0">
      <div className="flex items-center justify-center min-h-screen">
        <div className="w-80 md:w-2/6 py-4 bg-white flex flex-col items-center justify-center rounded-xl">
          <p className="text-2xl font-bold">Şifre İşlemleri</p>
          <div className="flex flex-col items-center">
            <form className="flex items-center flex-col justify-center">
              <input
                type="text"
                value={sifre}
                onChange={(e) => setSifre(e.target.value)}
                placeholder="Yeni Şifre"
                className="w-60 bg-neutral-300 placeholder-neutral-600 mt-3 rounded-full py-2 text-center"
                required
              />
              <button
                type="submit"
                onClick={handleDegistir}
                className="text-xl bg-green-500 text-white rounded-full mt-3 font-bold py-2 w-60"
              >
                Değiştir
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirmaIsim;
