import React from "react";

const GarsonModal = () => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 z-50 bg-opacity-70">
      <div className="bg-white p-5 rounded-lg shadow-2xl w-96 relative">
        <button className="absolute top-2 right-5 text-gray-500 hover:text-gray-700">
          X
        </button>
        <h2 className="font-semibold mb-4 text-lg">Garson Çağır</h2>
        <form>
          <select className="p-2 bg-neutral-100 rounded-md border w-full">
            <option value="">Masa Seçiniz</option>
          </select>
          <button
            className="w-full p-2 border mt-2 rounded-full bg-neutral-50"
            type="submit"
          >
            Çağır
          </button>
        </form>
      </div>
    </div>
  );
};

export default GarsonModal;
