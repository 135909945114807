import React, { useState } from "react";
import trendyol from "../../assets/img/logo/trendyol.png";
import n11 from "../../assets/img/logo/n11.png";
import hepsiburada from "../../assets/img/logo/hepsiburada.png";
import shopier from "../../assets/img/logo/shopier.png";
import hopdiye from "../../assets/img/logo/hopdiye.png";
import KayitModal from "../landing/KayitOl";

const QuestionModal = ({ onClose, onResponse }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-80 relative">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
        >
          X
        </button>
        <h3 className="text-lg font-semibold mb-2 text-center">
          Kayıtlı Kullanıcımız mısınız?
        </h3>
        <div className="flex justify-around mt-4">
          <button
            onClick={() => onResponse("yes")}
            className="bg-green-500 text-white py-2 px-4 rounded-md"
          >
            Evet
          </button>
          <button
            onClick={() => onResponse("no")}
            className="bg-red-500 text-white py-2 px-4 rounded-md"
          >
            Hayır
          </button>
        </div>
      </div>
    </div>
  );
};

const LinkModal = ({ onClose }) => {
  const [isQuestionModalOpen, setIsQuestionModalOpen] = useState(false);
  const [isKayitModalOpen, setIsKayitModalOpen] = useState(false);
  const [selectedPlatform, setSelectedPlatform] = useState(null);

  const platforms = [
    {
      name: "Trendyol",
      logo: trendyol,
      link: "https://www.trendyol.com/hopdiye/minimenu-menu-yonetim-ve-qr-menu-1-yillik-abonelik-p-837340570?boutiqueId=61&merchantId=163783",
    },
    {
      name: "N11",
      logo: n11,
      link: "https://www.n11.com/urun/minimenu-menu-yonetim-qr-menu-1-yillik-abonelik-55481619?magaza=hopdiye&pid=619677439",
    },
    {
      name: "Hepsiburada",
      logo: hepsiburada,
      link: "https://www.hepsiburada.com/hopdiye-minimenu-menu-yonetim-ve-qr-menu-1-yillik-abonelik-pm-HBC00006KKDY8",
    },
    { name: "Shopier", logo: shopier, link: "https://shopier.com/minimenu" },
    {
      name: "Hopdiye",
      logo: hopdiye,
      link: "https://www.hopdiye.com/minimenu-menu-yonetim-ve-qr-menu-1-yillik-abonelik",
    },
  ];

  const handlePlatformClick = (platform) => {
    setSelectedPlatform(platform);
    setIsQuestionModalOpen(true);
  };

  const handleResponse = (response) => {
    setIsQuestionModalOpen(false);
    if (response === "yes") {
      window.location.href = selectedPlatform.link;
    } else {
      setIsKayitModalOpen(true);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-96 relative">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
        >
          X
        </button>
        <h2 className="text-2xl font-semibold mb-4 text-center">
          Platform Seçiniz
        </h2>
        <div className="grid grid-cols-3 gap-4">
          {platforms.map((platform) => (
            <button
              key={platform.name}
              onClick={() => handlePlatformClick(platform)}
              className="flex flex-col items-center bg-gray-100 p-4 rounded-md shadow-md hover:bg-gray-200"
            >
              <img
                src={platform.logo}
                alt={platform.name}
                className="h-12 mb-2"
              />
              <span className="text-sm font-medium">{platform.name}</span>
            </button>
          ))}
        </div>
      </div>
      {isQuestionModalOpen && (
        <QuestionModal
          onClose={() => setIsQuestionModalOpen(false)}
          onResponse={handleResponse}
        />
      )}
      {isKayitModalOpen && (
        <KayitModal onClose={() => setIsKayitModalOpen(false)} />
      )}
    </div>
  );
};

export default LinkModal;
