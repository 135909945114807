import { Navigate, Outlet } from "react-router-dom";
import { getCookieValue } from "../services/getCookie";
import { sifreCoz } from "../services/getPass";
import { useEffect, useState } from "react";
import gif from "../assets/img/logo.png";

const MainLayout = () => {
  const token = getCookieValue("token");
  const [yukleniyor, setYukleniyor] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setYukleniyor(false);
    }, 1500);
  }, []);

  if (yukleniyor) {
    return (
      <div className="min-h-screen flex flex-col items-center justify-center">
        <img src={gif} className="w-60 animate-ping" />
      </div>
    );
  }

  if (token) {
    const cozulen = sifreCoz(token);
    if (cozulen.firmaKod) {
      return <Outlet />;
    }
  }

  return <Navigate to="/bayi/giris" replace />;
};
export default MainLayout;
