import React, { useRef } from "react";
import QRCode from "qrcode.react";

const FirmaIsim = ({ firmaK, firmaAd }) => {
  const qrRef = useRef();

  const handleDownload = () => {
    const canvas = qrRef.current.querySelector("canvas");
    const context = canvas.getContext("2d");

    const borderWidth = 24;
    const width = canvas.width + borderWidth * 2;
    const height = canvas.height + borderWidth * 2;

    const scaledCanvas = document.createElement("canvas");
    scaledCanvas.width = width;
    scaledCanvas.height = height;
    const scaledContext = scaledCanvas.getContext("2d");

    scaledContext.fillStyle = "#ffffff";
    scaledContext.fillRect(0, 0, width, height);

    const xPos = borderWidth;
    const yPos = borderWidth;

    scaledContext.drawImage(canvas, xPos, yPos);

    const dataUrl = scaledCanvas.toDataURL("image/jpeg", 1.0);
    const link = document.createElement("a");
    link.href = dataUrl;
    link.download = `${firmaK}_qrcode.jpg`;
    link.click();
  };

  const handlePrint = () => {
    const canvas = qrRef.current.querySelector("canvas");
    const dataUrl = canvas.toDataURL("image/jpeg", 1.0);
    const printWindow = window.open("", "PrintWindow", "width=800,height=600");
    printWindow.document.write(
      `<html><head><title>QR Code</title>
      <style>
        @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');
        @media print {
          body {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100vh;
            flex-direction: column;
            font-family: 'Poppins', sans-serif;
            margin: 0;
            padding: 0;
          }
          img {
            max-width: 80%;
            max-height: 80%;
            page-break-inside: avoid;
          }
          h1, h2, p {
            margin: 0;
            page-break-inside: avoid;
          }
          @page {
            margin: 0;
          }
        }
        body {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100vh;
          flex-direction: column;
          font-family: 'Poppins', sans-serif;
        }
        img {
          max-width: 30%;
          max-height: 30%;
        }
        h1 {
          font-size: 100px;
          margin: 1px;
        }
        h2 {
          font-size: 35px;
          margin: 0;
        }
        p {
          font-size: 24px;
          margin-top: 10px;
        }
      </style>
      </head>
      <body>
        <h2>${firmaAd}</h2>
        <h1>MENÜ</h1>
        <img src="${dataUrl}" />
        <p>minimenu.com.tr/${firmaK}</p>
      </body>
      </html>`
    );
    printWindow.document.close();
    printWindow.onload = () => {
      printWindow.print();
    };
  };

  return (
    <div className="fixed font-manrope bg-neutral-800 bg-opacity-70 overflow-y-auto inset-0">
      <div className="flex items-center justify-center min-h-screen">
        <div className="w-80 md:w-2/6 py-4 bg-white flex flex-col items-center justify-center rounded-xl">
          <p className="text-2xl font-bold">KAREKOD OLUŞTUR</p>
          <div className="relative hidden mt-4" ref={qrRef}>
            <QRCode
              value={`minimenu.com.tr/${firmaK}`}
              size={400}
              renderAs="canvas"
              bgColor="#ffffff"
              fgColor="#000000"
            />
          </div>
          <button
            className="mt-3 bg-green-400 py-2 w-64 rounded-xl text-white text-lg font-semibold"
            onClick={handleDownload}
          >
            İndir
          </button>
          <button
            className="mt-3 bg-orange-400 py-2 w-64 rounded-xl text-white text-lg font-semibold"
            onClick={handlePrint}
          >
            Çıktı Al
          </button>
        </div>
      </div>
    </div>
  );
};

export default FirmaIsim;
