import React, { useState } from "react";
import Papa from "papaparse";
import { db } from "../../firebase";
import { Timestamp, addDoc, collection } from "firebase/firestore";
import toast from "react-hot-toast";

const CSVModal = () => {
  const [data, setData] = useState([]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      Papa.parse(file, {
        header: true,
        complete: (results) => {
          setData(results.data);
        },
        error: (error) => {
          console.error("Error parsing CSV file:", error);
        },
      });
    }
  };

  const handleUpload = async () => {
    try {
      const menuRef = collection(db, "menu");
      for (const item of data) {
        await addDoc(menuRef, {
          kullanici: item.kullaniciadi,
          sifre: item.kulsifre,
          firmaKod: item.kullaniciadi,
          firmaAd: "-",
          tel: "-",
          durum: true,
          stamp: Timestamp.now(),
          createdAt: new Date(),
        });
      }
      toast.success("Kullanıcılar oluşturuldu.");
      window.location.reload();
    } catch (error) {
      toast.error("Hata oluştu.");
      console.error("Veriler yüklenirken bir hata oluştu:", error);
    }
  };

  const handleIndir = () => {
    const csvContent = "kullaniciadi;kulsifre\naa006;276732\naa007;337767";
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    const element = document.createElement("a");
    element.href = url;
    element.download = "ornekkul.csv";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  return (
    <div className="fixed font-manrope bg-neutral-800 bg-opacity-70 overflow-y-auto inset-0">
      <div className="flex items-center justify-center min-h-screen">
        <div className="w-80 md:w-2/6 py-4 bg-white flex flex-col items-center justify-center rounded-xl">
          <p className="text-2xl font-bold">Toplu Kullanıcı Oluştur</p>
          <div className="flex flex-col items-center">
            <form className="flex items-center flex-col justify-center">
              <input
                type="file"
                accept=".csv"
                onChange={handleFileChange}
                placeholder="CSV Dosyası Seç"
                className="w-64 px-2 bg-neutral-300 placeholder-neutral-600 mt-3 rounded-full py-2 text-center"
                required
              />
              <button
                type="button"
                onClick={handleIndir}
                className="text-xl bg-zinc-400 text-white rounded-full mt-3 font-bold py-2 w-60"
              >
                Örnek CSV
              </button>
              <button
                type="button"
                onClick={handleUpload}
                className="text-xl bg-green-400 text-white rounded-full mt-3 font-bold py-2 w-60"
              >
                Yükle
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CSVModal;
