import React, { useEffect, useState } from "react";
import { getCookieValue } from "../../services/getCookie";
import { sifreCoz } from "../../services/getPass";
import { db } from "../../firebase";
import {
  collection,
  getDocs,
  query,
  where,
  updateDoc,
  doc,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import imageCompression from "browser-image-compression";
import toast from "react-hot-toast";

const Resimler = () => {
  const [resimler, setResimler] = useState([]);
  const [firmaKodu, setFirmaKodu] = useState("");
  const [cozulmus, setCozulmus] = useState({});
  const token = getCookieValue("token");
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      tokenKontrol(token);
    } else {
      navigate("/");
    }
  }, [token]);

  useEffect(() => {
    const getResimler = async () => {
      try {
        const mRef = collection(db, "menu");
        const qM = query(mRef, where("firmaKod", "==", firmaKodu));
        const getM = await getDocs(qM);
        if (!getM.empty) {
          const getData = getM.docs[0].data();
          getData.id = getM.docs[0].id;
          setCozulmus(getData);
          setResimler(getData.isletmeResim || []);
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (firmaKodu) {
      getResimler();
    }
  }, [firmaKodu]);

  const tokenKontrol = (gelenToken) => {
    const cozulmusVeri = sifreCoz(gelenToken);
    if (cozulmusVeri.firmaKod) {
      setFirmaKodu(cozulmusVeri.firmaKod);
    } else {
      navigate("/");
    }
  };

  const handleSave = async () => {
    try {
      const docRef = doc(db, "menu", cozulmus.id);
      await updateDoc(docRef, { isletmeResim: resimler });
      toast.success("Resimler başarıyla güncellendi!");
    } catch (error) {
      console.log("Error updating document: ", error);
      toast.error("Resimler güncellenirken bir hata oluştu.");
    }
  };

  const handleRemove = async (index) => {
    try {
      const updatedResimler = [...resimler];
      updatedResimler.splice(index, 1);

      const docRef = doc(db, "menu", cozulmus.id);
      await updateDoc(docRef, { isletmeResim: updatedResimler });

      setResimler(updatedResimler);
      toast.success("Resim başarıyla kaldırıldı.");
    } catch (error) {
      console.log("Error removing document: ", error);
      toast.error("Resim kaldırılırken bir hata oluştu.");
    }
  };

  const handleAddResim = () => {
    if (resimler.length >= 5) {
      toast.error("En fazla 5 resim ekleyebilirsiniz.");
      return;
    }
    const newResim = { resim: "" };
    setResimler([...resimler, newResim]);
    toast.success("Listeye yeni resim eklendi. Gerekli alanları doldurunuz.");
  };

  const handleResimChange = async (e, index) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const options = {
          maxSizeMB: 0.1,
          maxWidthOrHeight: 1000,
          useWebWorker: true,
        };
        const compressedFile = await imageCompression(file, options);
        const reader = new FileReader();
        reader.onloadend = () => {
          const updatedResimler = [...resimler];
          updatedResimler[index].resim = reader.result;
          setResimler(updatedResimler);
        };
        reader.readAsDataURL(compressedFile);
      } catch (error) {
        console.error("Image compression failed:", error);
        toast.error("Resim sıkıştırma başarısız oldu.");
      }
    }
  };

  return (
    <div className="min-h-screen">
      <div>
        <img
          src={cozulmus.resimURL}
          className="w-40 pt-2 mx-auto"
          alt="Firma Resmi"
        />
        <p className="text-center text-4xl font-bold">{cozulmus.firmaAd}</p>
        <p className="text-xl mt-1 font-light text-center">İŞLETME RESİMLERİ</p>
        <p className="py-2 bg-orange-300 bg-opacity-25 text-sm text-center w-full max-w-sm md:max-w-xl mx-auto border rounded-lg mt-2">
          Lütfen İşlemleri Yaptıktan Sonra
          <span className="font-semibold"> Kaydet </span> Düğmesine Tıklayalım.
        </p>
        <div className="flex mt-4 items-center justify-center px-2 md:px-0 gap-2 pb-2 flex-col">
          {resimler.length > 0 ? (
            resimler.map((resim, index) => (
              <div
                key={index}
                className="p-2 bg-neutral-100 flex items-center flex-col gap-1 justify-center md:w-2/6 w-full rounded-xl"
              >
                <div className="flex items-center gap-3 w-full justify-between">
                  {resim.resim ? (
                    <img
                      src={resim.resim}
                      className="w-full mx-auto bg-cover h-[240px]"
                      alt={`Resim ${index}`}
                    />
                  ) : (
                    <p>Resim seçilmedi</p>
                  )}
                </div>
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleResimChange(e, index)}
                />
                <button
                  onClick={() => handleRemove(index)}
                  className="w-full py-1 text-white text-lg font-bold active:scale-105 hover:bg-red-600 bg-red-500 rounded-xl"
                >
                  Kaldır
                </button>
              </div>
            ))
          ) : (
            <p className="text-center text-xl font-bold">RESİM BULUNAMADI.</p>
          )}
        </div>
        <div className="flex gap-2 items-center mb-5 flex-col bottom-5 lg:fixed lg:transform lg:right-10 lg:translate-x-0">
          <button
            onClick={handleAddResim}
            className="w-60 lg:w-full text-white py-2 bg-blue-400 rounded-xl text-md font-bold"
          >
            Oluştur
          </button>
          <div className="flex items-center gap-2">
            <button
              onClick={() => navigate("/bayi/panel")}
              className="w-28 md:w-40 text-white py-2 bg-orange-500 rounded-xl md:text-md font-bold"
            >
              Anasayfa
            </button>
            <button
              onClick={handleSave}
              className="w-28 md:w-40 text-white py-2 bg-green-400 rounded-xl text-md font-bold"
            >
              Kaydet
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Resimler;
