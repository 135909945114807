import React, { useEffect, useState } from "react";
import { db } from "../../../firebase";
import {
  collection,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import toast from "react-hot-toast";

const FirmaIsim = ({ firmaK }) => {
  const [yeniFirmaAd, setYeniFirmaAd] = useState("");
  const [cozulmus, setCozulmus] = useState({});

  useEffect(() => {
    const getDurum = async () => {
      try {
        toast.loading("Veri çekiliyor...");
        const mRef = collection(db, "menu");
        const qM = query(mRef, where("firmaKod", "==", firmaK));
        const getM = await getDocs(qM);
        if (!getM.empty) {
          const getData = getM.docs[0].data();
          getData.id = getM.docs[0].id;
          setCozulmus(getData);
          toast.dismiss();
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (firmaK) {
      getDurum();
    }
  }, [firmaK]);

  useEffect(() => {
    setYeniFirmaAd(cozulmus.firmaAd ? cozulmus.firmaAd : "");
  }, [cozulmus]);

  const handleDegistir = async (e) => {
    e.preventDefault();
    try {
      const menuRef = collection(db, "menu");
      const mQ = query(menuRef, where("firmaKod", "==", firmaK));
      const getDoc = await getDocs(mQ);

      if (yeniFirmaAd === "") {
        toast.error("Lütfen Firma Adı giriniz.");
      } else {
        getDoc.forEach(async (donenDoc) => {
          const docRef = donenDoc.ref;
          await updateDoc(docRef, {
            firmaAd: yeniFirmaAd,
          });
        });

        toast.success("Firma adı başarıyla güncellendi");
      }
    } catch (error) {
      toast.error("Sistemde hata oluştu.");
      console.log(error);
    }
  };

  return (
    <div className="fixed font-manrope bg-neutral-800 bg-opacity-70 overflow-y-auto inset-0">
      <div className="flex items-center justify-center min-h-screen">
        <div className="w-80 md:w-2/6 px-2 py-4 bg-white flex flex-col items-center justify-center rounded-xl">
          <p className="text-2xl font-bold">Firma Adı</p>
          <div className="flex flex-col w-full items-center">
            <form className="flex items-center flex-col w-full justify-center">
              <input
                type="text"
                value={yeniFirmaAd}
                onChange={(e) => setYeniFirmaAd(e.target.value)}
                placeholder="Firma Adı"
                className="w-full bg-neutral-300 placeholder-neutral-600 mt-3 rounded-full py-2 text-center"
                required
              />
              <button
                type="submit"
                onClick={handleDegistir}
                className="text-xl bg-gray-200 rounded-full mt-3 font-bold py-2 w-full"
              >
                Değiştir
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirmaIsim;
