import React, { useState } from "react";
import LinkModal from "../../modals/landing/LinkModal";
import QR from "../../assets/img/qr.png";
import { useNavigate } from "react-router-dom";

const Slide3 = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="py-5 flex flex-col items-center justify-center bg-white">
      <img
        src={QR}
        alt="qr"
        className="w-60 cursor-pointer"
        onClick={() => {
          navigate("/aa001");
        }}
      />
      <p className="text-center">
        Örnek menüyü görmek için QR kodu okut veya üzerine tıkla.
      </p>
      <p className="text-center mt-3 text-3xl">
        İşletmenizi QR Menü`ye taşıyın
      </p>
      <h1 className="text-6xl font-bold mt-2">600 ₺/YIL</h1>
      <button
        className="py-3 bg-red-500 w-80 rounded-xl text-white font-bold mt-3"
        onClick={handleOpenModal}
      >
        Hemen Satın Al
      </button>
      {isModalOpen && <LinkModal onClose={handleCloseModal} />}
    </div>
  );
};

export default Slide3;
