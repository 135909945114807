import React, { useEffect, useState } from "react";
import { db, storage } from "../../../firebase";
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import {
  arrayUnion,
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import imageCompression from "browser-image-compression";
import { generateRandomCode } from "../../../utils/kodOlustur";
import toast from "react-hot-toast";

const UrunEkle = ({ firmaK }) => {
  const [aktifGrup, setAktifGrup] = useState([]);
  const [compressedFile, setCompressedFile] = useState();
  const [aciklama, setUrunAciklama] = useState("");
  const [secilenGrup, setSecilenGrup] = useState("");
  const [urunAdi, setUrunAdi] = useState("");
  const [urunMiktar, setUrunMiktar] = useState("");
  const [urunFiyat, setUrunFiyat] = useState("");
  const siraNo = 1;
  const [yukleniyor, setYukleniyor] = useState(true);

  useEffect(() => {
    getGrups();
    setYukleniyor(false);
  }, [firmaK]);

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const options = {
        maxSizeMB: 0.1,
        maxWidthOrHeight: 1024,
        useWebWorker: true,
      };

      try {
        setCompressedFile(await imageCompression(file, options));
      } catch (error) {
        console.log(error);
        toast.error("Resim sıkıştırma işlemi başarısız oldu.");
      }
    }
  };

  const getGrups = async () => {
    try {
      if (firmaK) {
        const mRef = collection(db, "menu");
        const mQ = query(mRef, where("firmaKod", "==", firmaK));
        const getGroup = await getDocs(mQ);

        if (!getGroup.empty) {
          const data = getGroup.docs[0].data().urunGrup;
          setAktifGrup(data);
          setYukleniyor(false);
        } else {
          toast.error("Firma kodu geçersiz");
          setYukleniyor(false);
        }
      }
    } catch (error) {
      toast.error("Sistemde bir hata oluştu.");
      console.log(error);
    }
  };

  const handleEkle = async (e) => {
    e.preventDefault();

    const kod = generateRandomCode(7);
    let downloadURL = null;
    if (compressedFile) {
      try {
        // Create a reference to 'menu/firmaK/compressedFile.name'
        const storageRef = ref(storage, `${firmaK}/${secilenGrup}/${kod}`);

        // Upload the compressed image
        await uploadBytes(storageRef, compressedFile);
        console.log('Upload successful!');

        // Get the download URL
        downloadURL = await getDownloadURL(storageRef);
        console.log('File available at', downloadURL);
      }
      catch (error) {
        console.log(error);
        toast.error("Resim yüklenirken hata oluştu.");
      }
    }

    try {
      const mRef = collection(db, "menu");
      const mQ = query(mRef, where("firmaKod", "==", firmaK));
      const getId = await getDocs(mQ);
      const gelenId = getId.docs[0].id;

      const yeniRef = doc(db, "menu", gelenId);
      await updateDoc(yeniRef, {
        menu: arrayUnion({
          ad: urunAdi,
          fiyat: urunFiyat,
          miktar: urunMiktar,
          aciklama,
          resim: downloadURL,
          kod: kod, //TODO: NEDEN???
          kategori: secilenGrup,
          siraNo: siraNo
        }),
      });

      handleSifirla();
      toast.success("Ürün eklendi.");
    } catch (error) {
      console.log(error);
      toast.error("Ürün eklerken hata oluştu.");
    }
  };

  const handleSifirla = () => {
    setSecilenGrup("");
    setUrunAdi("");
    setUrunMiktar("");
    setUrunFiyat("");
  };

  return (
    <div className="fixed font-manrope bg-neutral-800 bg-opacity-70 overflow-y-auto inset-0">
      <div className="flex items-center justify-center min-h-screen">
        {yukleniyor ? (
          <p>Yükleniyor..</p>
        ) : (
          <div className="w-80 md:w-2/6 py-4 bg-white flex flex-col items-center justify-center rounded-xl">
            <p className="text-2xl font-bold">Ürün Ekle</p>
            <form className="flex flex-col gap-2 items-center justify-center">
              <select
                value={secilenGrup}
                required
                onChange={(e) => setSecilenGrup(e.target.value)}
                className="w-60 md:w-80 outline-none bg-neutral-200 mt-2 rounded-full py-2 text-center"
              >
                <option value="">Grup Seçiniz</option>
                {aktifGrup.map((grup, index) => (
                  <option key={index} value={grup}>
                    {grup}
                  </option>
                ))}
              </select>
              <input
                type="text"
                className="w-60 md:w-80 py-2 bg-neutral-200 outline-none text-center rounded-full"
                placeholder="Ürün Adı"
                value={urunAdi}
                onChange={(e) => setUrunAdi(e.target.value)}
                required
              />
              <textarea
                placeholder="Ürün Açıklaması"
                value={aciklama}
                onChange={(e) => setUrunAciklama(e.target.value)}
                className="bg-neutral-200 w-60 md:w-80 p-2 outline-none  rounded-xl max-h-52"
              ></textarea>
              <input
                type="text"
                className="w-60 md:w-80 py-2 bg-neutral-200 outline-none text-center rounded-full "
                placeholder="Miktar (GR, KG)"
                value={urunMiktar}
                onChange={(e) => setUrunMiktar(e.target.value)}
                required
              />
              <input
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                className="text-md px-4 bg-neutral-200 rounded-full w-60 md:w-80 py-2"
                placeholder="Ürün Resmi"
              />
              <input
                type="number"
                placeholder="Fiyat"
                value={urunFiyat}
                onChange={(e) => setUrunFiyat(e.target.value)}
                className="w-60 md:w-80 py-2 bg-neutral-200 outline-none text-center rounded-full"
              />

              <button
                type="submit"
                onClick={handleEkle}
                className="text-xl hover:bg-green-600 active:scale-105 bg-green-400 text-white rounded-full mt-3 font-bold py-2 w-60"
              >
                Ekle
              </button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default UrunEkle;
