import React, { useEffect, useState } from "react";
import { db } from "../../../firebase";
import {
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import toast from "react-hot-toast";

const FirmaIsim = ({ firmaK }) => {
  const [yeniHakkimizda, setYeniHakkimizda] = useState("");
  const [yeniYorumlar, setYeniYorumlar] = useState("");
  const [cozulmus, setCozulmus] = useState({});

  useEffect(() => {
    const getDurum = async () => {
      try {
        toast.loading("Veri çekiliyor..");
        const mRef = collection(db, "menu");
        const qM = query(mRef, where("firmaKod", "==", firmaK));
        const getM = await getDocs(qM);
        if (!getM.empty) {
          const getData = getM.docs[0].data();
          getData.id = getM.docs[0].id;
          setCozulmus(getData);
          toast.dismiss();
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (firmaK) {
      getDurum();
    }
  }, [firmaK]);

  useEffect(() => {
    setYeniHakkimizda(cozulmus.hakkimizda ? cozulmus.hakkimizda : "");
    setYeniYorumlar(cozulmus.yorumlar ? cozulmus.yorumlar : "");
  }, [cozulmus]);

  const handleDegistir = async (e) => {
    e.preventDefault();
    try {
      const menuRef = collection(db, "menu");
      const mQ = query(menuRef, where("firmaKod", "==", firmaK));
      const getDoc = await getDocs(mQ);

      if (yeniHakkimizda === "") {
        toast.error("Lütfen Hakkımızda Kısmını doldurunuz");
      } else {
        getDoc.forEach(async (donenDoc) => {
          const docRef = donenDoc.ref;
          await updateDoc(docRef, {
            yorumlar: yeniYorumlar,
            hakkimizda: yeniHakkimizda,
          });
        });

        toast.success(
          "Firma hakkımızda ve Yorumlar linki başarıyla güncellendi"
        );
      }
    } catch (error) {
      toast.error("Sistemde hata oluştu.");
      console.log(error);
    }
  };

  const handleHakkimdaGoster = async () => {
    try {
      const docRef = doc(db, "menu", cozulmus.id);
      await updateDoc(docRef, { hakkimdaDurum: true });
      toast.success("Hakkımızda kısmı aktif hale getirildi.");
    } catch (error) {
      toast.error("Hata var.");
      console.log(error);
    }
  };

  const handleHakkimdaGizle = async () => {
    try {
      const docRef = doc(db, "menu", cozulmus.id);
      await updateDoc(docRef, { hakkimdaDurum: false });
      toast.success("Hakkımızda kısmı pasif hale getirildi.");
    } catch (error) {
      toast.error("Hata var.");
      console.log(error);
    }
  };

  return (
    <div className="fixed font-manrope bg-neutral-800 bg-opacity-70 overflow-y-auto inset-0">
      <div className="flex items-center justify-center min-h-screen">
        <div className="w-80 md:w-2/6 px-2 py-4 bg-white flex flex-col items-center justify-center rounded-xl">
          <p className="text-2xl font-bold">Firma Hakkında</p>
          <div className="flex flex-col w-full items-center">
            <form className="flex items-center flex-col w-full justify-center">
              <textarea
                value={yeniHakkimizda}
                onChange={(e) => setYeniHakkimizda(e.target.value)}
                placeholder="Hakkımızda Metni"
                className="bg-neutral-300 w-full placeholder-neutral-600 mt-3 rounded-lg py-2 px-2"
              />
              <textarea
                value={yeniYorumlar}
                onChange={(e) => setYeniYorumlar(e.target.value)}
                placeholder="Yorumlar Linki"
                className="bg-neutral-300 w-full placeholder-neutral-600 mt-3 rounded-lg py-2 px-2"
              />{" "}
              <button
                type="submit"
                onClick={handleDegistir}
                className="text-xl bg-gray-200 rounded-full mt-3 font-bold py-2 w-full"
              >
                Değiştir
              </button>
            </form>
            <div className="mt-3 mb-3 border w-full"></div>
            <p className="text-center bg-orange-300 border bg-opacity-20 rounded-xl py-1 max-w-xl mb-2">
              Aşağıdaki düğmeleri kullanarak{" "}
              <span className="font-bold">Hakkımızda</span> kısmını kendi menü
              sayfanızdan gizleyip gösterebilirsiniz.
            </p>
            <div className="flex items-center space-x-4">
              <button
                onClick={handleHakkimdaGoster}
                className="text-xl text-white bg-green-500 rounded-full  font-bold py-2 w-32 md:w-60"
              >
                Göster
              </button>
              <button
                onClick={handleHakkimdaGizle}
                className="text-xl text-white bg-red-500 rounded-full font-bold py-2 w-32 md:w-60"
              >
                Gizle
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirmaIsim;
