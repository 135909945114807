import React from "react";
import Header from "../components/landing/Header";
import Hero from "../components/landing/Hero";
import Footer from "../components/landing/Footer";
import Slide from "../components/landing/Slide";
import Slide2 from "../components/landing/Slide2";
import Contact from "../components/landing/Contact";
import Slide3 from "../components/landing/Slide3";

const Landing = () => {
  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      <Hero />
      <Slide2 />
      <Slide />
      <Contact />
      <Slide3 />
      <Footer />
    </div>
  );
};

export default Landing;
