import React from "react";

const Slide = () => {
  return (
    <div>
      <section>
        <div className="px-2 py-5 mx-auto md:px-12 lg:px-20 max-w-7xl">
          <div className="text-center">
            <div>
              <p className="text-4xl font-semibold tracking-tighter text-gray-900">
                Sıkça Sorulan Sorular
              </p>
              <p className="mx-auto mt-4 text-base font-medium text-gray-500 text-balance">
                Aklınıza takılan bir şey kalmasın
              </p>
            </div>
          </div>
          <div className="p-2 mt-5 border bg-gray-50 rounded-3xl">
            <div className="flex flex-col gap-6 p-10 text-base text-gray-400 bg-white border shadow-lg rounded-3xl">
              <details>
                <summary className="text-lg font-medium text-black cursor-pointer">
                  QR Menü Nedir?
                </summary>
                <p className="pt-4 text-base font-medium text-gray-500 text-balance">
                  Karekodlar (QR) en basit anlamıyla metin içeren anahtarlardır.
                  Bunlar bir metin bilgisi taşıyabilecekleri gibi daha çok
                  girilmesi zahmetli uzun adreslerin okunabilmesi için
                  kullanılmaktadır. QR menüler de aslında müşterinizi internette
                  yayınlamış olduğunuz dijital menünüze yönlendiren adres
                  anahtarlarıdır.
                </p>
              </details>
              <details>
                <summary className="text-lg font-medium text-black cursor-pointer">
                  Minimenü Nedir?
                </summary>
                <p className="pt-4 text-base font-medium text-gray-500 text-balance">
                  Minimenü de karekod okutularak ulaşılan bir menü sayfasıdır.
                  Yani aslında minimenü.com.tr adresi altında size ayrılmış tek
                  sayfalık bir web adresidir. Kullanmak için yapmanız gereken
                  sadece kullanıcı profilinizi oluşturup ürünlerinizi girmektir.
                </p>
              </details>
              <details>
                <summary className="text-lg font-medium text-black cursor-pointer">
                  Minimenü Avantajları Nelerdir?
                </summary>
                <p className="pt-4 text-base font-medium text-gray-500 text-balance">
                  Minimenü, basit olan en iyisidir yaklaşımıyla hazırlanmıştır.
                  Kullanıcı dostu sade bir tasarıma sahiptir. Küçük-orta ölçekli
                  bir restoran veya kafenin tüm menü ihtiyacını karşılar. Büyük
                  olasılıkla hiç bir zaman ihtiyaç duymayacağınız onlarca
                  karmaşık özellik barındırmaz.
                </p>
              </details>
              <details>
                <summary className="text-lg font-medium text-black cursor-pointer">
                  Minimenü Nasıl Kullanılır?
                </summary>
                <p className="pt-4 text-base font-medium text-gray-500 text-balance">
                  1.Web sitemizdeki deneme sürümü veya satın alma
                  seçeneklerinden birine tıklanarak üye olunur. <br />
                  <br /> 2.Ardından Menü bilgileri girilir. <br />
                  <br />
                  3.Karekod yazdır nutonuna basılarak veya karekod indirilerek
                  çıktı alınır ve kullanılır.
                  <br />
                  <br />
                  4. İstenirse yazdır butonuna basarak menü yazdırılır ve
                  müşterinin okuması için çıktı alınarak kullanılabilir.
                </p>
              </details>
              <details>
                <summary className="text-lg font-medium text-black cursor-pointer">
                  Menüdeki Bilgiler Nasıl Değiştirilir?
                </summary>
                <p className="pt-4 text-base font-medium text-gray-500 text-balance">
                  Menünüz sizindir. Kullanıcı kodunuz hariç tüm bilgilerinizi
                  istediğiniz zaman değiştirebilirsiniz değiştirebilirsiniz. Bu
                  işlemleri nasıl yapacağınızı öğrenmek için kanalımızdaki kısa
                  videoları izleyebilirsiniz.
                </p>
              </details>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Slide;
