import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./pages/Bayi/Login";
import YLogin from "./pages/Yonetici/Login";
import MHome from "./pages/Musteri/Home";
import Panel from "./pages/Bayi/Panel";
import AuthLayout from "./layouts/AuthLayout";
import BayiLayout from "./layouts/BayiLayout";
import YoneticiLayout from "./layouts/YoneticiLayout";
import YPanel from "./pages/Yonetici/Panel";
import Urunler from "./pages/Bayi/Urunler";
import Duyurular from "./pages/Bayi/Duyurular";
import Kampanyalar from "./pages/Bayi/Kampanyalar";
import IsletmeResim from "./pages/Bayi/IsletmeResim";
import Landing from "./pages/Landing";
import { Toaster } from "react-hot-toast";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route element={<AuthLayout />}>
            <Route path="/bayi/giris" element={<Login />} />
            <Route path="/yonetici/giris" element={<YLogin />} />
          </Route>
          <Route element={<BayiLayout />}>
            <Route path="/bayi/panel" element={<Panel />} />
            <Route path="/bayi/duzenle" element={<Urunler />} />
            <Route path="/bayi/duyurular" element={<Duyurular />} />
            <Route path="/bayi/kampanyalar" element={<Kampanyalar />} />
            <Route path="/bayi/resimler" element={<IsletmeResim />} />
          </Route>
          <Route element={<YoneticiLayout />}>
            <Route path="/yonetici/panel" element={<YPanel />} />
          </Route>
          <Route path="/" element={<Landing />} />
          <Route path="/:firma" element={<MHome />} />
        </Routes>
      </BrowserRouter>
      <Toaster />
    </div>
  );
}

export default App;
