import React, { useEffect, useState } from "react";
import logo from "../../assets/img/logo.png";
import right from "../../assets/img/login/video.mp4";
import { getCookieValue } from "../../services/getCookie";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebase";
import { sifreCoz, veriSifrele } from "../../services/getPass";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const Login = () => {
  const [kullaniciAdi, setKullaniciAdi] = useState("");
  const [girilenSifre, setGirilenSifre] = useState("");
  const token = getCookieValue("token");
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      tokenKontrol(token);
    } else {
      return;
    }
  }, [token]);

  const tokenKontrol = (gelenToken) => {
    const cozulmus = sifreCoz(gelenToken);
    if (cozulmus.yetki) {
      navigate("/yonetici/panel");
    } else {
      return;
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const ref = collection(db, "yonetici");
      const q = query(
        ref,
        where("kul", "==", kullaniciAdi),
        where("sifre", "==", girilenSifre)
      );
      const getSnap = await getDocs(q);
      if (!getSnap.empty) {
        const data = getSnap.docs[0].data();
        const id = getSnap.docs[0].id;
        const yetki = data.yetki;
        const sifrelenecek = { yetki };
        const sifrele = veriSifrele(sifrelenecek);
        document.cookie = `token=${sifrele}; path=/`;
        document.cookie = `id=${id}; path=/`;
        navigate("/yonetici/panel");
      } else {
        toast.error("Girilen bilgiler geçersiz");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center">
      <div className="max-w-5xl flex w-full h-auto md:shadow md:bg-neutral-100 rounded-xl">
        <div className="flex-1 flex flex-col">
          <div className="md:pl-10 flex items-center md:items-start flex-col justify-center md:mt-6">
            <img
              src={logo}
              className="w-28 p-2 mx-auto md:mx-0 bg-black rounded-xl"
            />
            <h1 className="mt-3 font-bold text-2xl text-center md:text-left">
              Yönetici Girişi
            </h1>
            <form className="flex flex-col items-center justify-center">
              <input
                type="text"
                placeholder="Kullanıcı Adı"
                value={kullaniciAdi}
                required
                onChange={(e) => setKullaniciAdi(e.target.value)}
                className="py-4 w-80 tracking-widest md:w-96 mt-4 mb-3 border-b-2 md:bg-white bg-gray-100 font-semibold outline-none shadow-lg border-neutral-200 rounded-xl px-2"
              />
              <input
                type="password"
                value={girilenSifre}
                required
                onChange={(e) => setGirilenSifre(e.target.value)}
                placeholder="Şifre"
                className="py-4 w-80 md:bg-white bg-gray-100 md:w-96 border-b-2 mb-4 font-semibold outline-none shadow-lg border-neutral-200 rounded-xl px-2"
              />
              <button
                type="submit"
                onClick={handleLogin}
                className="py-4 mb-0 md:mb-5 w-80 active:scale-105 md:w-96 border-b-2 hover:bg-neutral-100 text-lg md:bg-white bg-gray-100 font-bold shadow-lg md:border-neutral-500 border-gray-300 rounded-xl"
              >
                Giriş Yap
              </button>
            </form>
          </div>
        </div>
        <div className="md:block hidden flex-1">
          <video
            src={right}
            autoPlay={true}
            className="w-full h-full object-cover rounded-r-xl"
          />
        </div>
      </div>
    </div>
  );
};

export default Login;
