import React from "react";
import toast from "react-hot-toast";

const ErrorModal = ({ kod }) => {
  const handleCopy = () => {
    const link = `minimenu.com.tr/${kod}`;
    navigator.clipboard
      .writeText(link)
      .then(() => {
        toast.success("Bağlantı kopyalandı!");
      })
      .catch((error) => {
        console.error("Kopyalama işlemi başarısız oldu:", error);
      });
  };

  return (
    <div className="fixed z-50 font-manrope inset-0 bg-neutral-800 bg-opacity-70 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen">
        <div className="w-80 md:w-2/6 py-4 bg-white flex flex-col items-center justify-center rounded-xl">
          <p className="text-lg font-bold tracking-wide">Paylaşım Linki</p>
          <p className="py-2 bg-neutral-200 rounded-full w-60 text-center mt-2 text-lg font-bold">
            minimenu.com.tr/{kod}
          </p>
          <button
            onClick={handleCopy}
            className="py-2 w-60 active:scale-105 mt-2 text-lg font-bold bg-neutral-300 shadow rounded-full"
          >
            Kopyala!
          </button>
        </div>
      </div>
    </div>
  );
};

export default ErrorModal;
