import React, { useEffect, useState } from "react";
import { db } from "../../../firebase";
import {
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import toast from "react-hot-toast";

const UrunGrup = ({ firmaK }) => {
  const [grupAdi, setGrupAdi] = useState("");
  const [secilenGrup, setSecilenGrup] = useState("");
  const [aktifGrup, setAktifGrup] = useState([]);

  useEffect(() => {
    const getGrups = async () => {
      try {
        if (firmaK) {
          const mRef = collection(db, "menu");
          const mQ = query(mRef, where("firmaKod", "==", firmaK));
          const getGroup = await getDocs(mQ);

          if (!getGroup.empty) {
            const data = getGroup.docs[0].data().urunGrup;
            setAktifGrup(data || []);
          } else {
            toast.error("Firma kodu geçersiz");
          }
        }
      } catch (error) {
        toast.error("Sistem de bir hata oluştu.");
        console.log(error);
      }
    };

    getGrups();
  }, [firmaK]);

  const handleEkle = async (e) => {
    e.preventDefault();
    try {
      const mRef = collection(db, "menu");
      const mQ = query(mRef, where("firmaKod", "==", firmaK));
      const getId = await getDocs(mQ);
      const gelenId = getId.docs[0].id;

      const yeniRef = doc(db, "menu", gelenId);

      if (grupAdi == "") {
        toast.error("Grup adı yazınız.");
      } else {
        await updateDoc(yeniRef, {
          urunGrup: arrayUnion(grupAdi),
        });

        setAktifGrup([...aktifGrup, grupAdi]);
        setGrupAdi("");
        toast.success("Grup eklendi.");
      }
    } catch (error) {
      console.log(error);
      toast.error("Hata oluştu.");
    }
  };

  const handleSil = async (e) => {
    e.preventDefault();
    try {
      if (
        !window.confirm(
          "Ürün grubunu silerseniz bu gruptaki tüm ürünler de silinecektir. Emin misiniz?"
        )
      ) {
        return;
      }

      const mRef = collection(db, "menu");
      const mQ = query(mRef, where("firmaKod", "==", firmaK));
      const getId = await getDocs(mQ);
      const gelenId = getId.docs[0].id;

      const docRef = doc(db, "menu", gelenId);
      if (secilenGrup === "") {
        toast.error("Grup seçiniz");
      } else {
        const menuDoc = await getDocs(mQ);
        if (!menuDoc.empty) {
          const menuData = menuDoc.docs[0].data().menu || [];
          const filteredMenu = menuData.filter(
            (item) => item.kategori !== secilenGrup
          );

          await updateDoc(docRef, {
            urunGrup: arrayRemove(secilenGrup),
            menu: filteredMenu,
          });

          setAktifGrup(aktifGrup.filter((grup) => grup !== secilenGrup));
          setSecilenGrup("");
          toast.success("Grup ve ona ait ürünler başarıyla silindi!");
        } else {
          toast.error("Menu dokümanı bulunamadı.");
        }
      }
    } catch (error) {
      console.log(error);
      toast.error("Sistem de hata oluştu.");
    }
  };

  return (
    <div className="fixed font-manrope bg-neutral-800 bg-opacity-70 overflow-y-auto inset-0">
      <div className="flex items-center justify-center min-h-screen">
        <div className="w-80 md:w-2/6 py-4 bg-white flex flex-col items-center justify-center rounded-xl">
          <p className="text-lg font-bold">Ürün Grubu Ekle</p>
          <div className="flex flex-col items-center">
            <div className="flex-1">
              <form className="flex items-center flex-col justify-center">
                <input
                  type="text"
                  value={grupAdi}
                  onChange={(e) => setGrupAdi(e.target.value)}
                  placeholder="Grup Adı (orn. Ana Yemek)"
                  className="w-60 bg-neutral-300 placeholder-neutral-600 mt-3 rounded-full py-2 text-center"
                  required
                />
                <button
                  type="submit"
                  onClick={handleEkle}
                  className="text-xl bg-gray-200 rounded-full mt-3 font-bold py-2 w-60"
                >
                  Ekle
                </button>
              </form>
            </div>
            {Array.isArray(aktifGrup) && (
              <div className="flex-1 mt-5">
                <p className="text-lg font-bold text-center">
                  Aktif Ürün Grupları
                </p>
                <form className="flex flex-col gap-2 items-center justify-center">
                  <select
                    value={secilenGrup}
                    required
                    onChange={(e) => setSecilenGrup(e.target.value)}
                    className="w-60 bg-neutral-300 mt-2 rounded-full py-2 text-center"
                  >
                    <option value="">Seçiniz</option>
                    {aktifGrup.map((grup, index) => (
                      <option key={index} value={grup}>
                        {grup}
                      </option>
                    ))}
                  </select>
                  <button
                    type="submit"
                    onClick={handleSil}
                    className="text-xl bg-red-600 text-white rounded-full mt-3 font-bold py-2 w-60"
                  >
                    Sil
                  </button>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UrunGrup;
