import React, { useState } from "react";
import { db } from "../../../firebase";
import {
  collection,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import toast from "react-hot-toast";

const FirmaIsim = ({ firmaK }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [base64, setBase64] = useState("");

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setBase64(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (base64) {
      try {
        const q = query(
          collection(db, "menu"),
          where("firmaKod", "==", firmaK)
        );
        const querySnapshot = await getDocs(q);

        querySnapshot.forEach(async (doc) => {
          await updateDoc(doc.ref, { resimURL: base64 });
        });

        toast.success("Resim değiştirildi.");
      } catch (error) {
        toast.error("Sistemde hata oluştu.");
        console.error("Dosya yüklenirken bir hata oluştu: ", error);
      }
    }
  };

  return (
    <div className="fixed font-manrope bg-neutral-800 bg-opacity-70 overflow-y-auto inset-0">
      <div className="flex items-center justify-center min-h-screen">
        <div className="w-80 md:w-2/6 py-4 bg-white flex flex-col items-center justify-center rounded-xl">
          <p className="text-2xl font-bold">Firma Logo Değiştir</p>
          <div className="flex flex-col items-center">
            <form
              onSubmit={handleSubmit}
              className="flex items-center flex-col justify-center"
            >
              <input
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                className="text-md px-4 bg-gray-200 rounded-full mt-3 font-bold py-2 w-72 md:w-80"
                required
              />
              <button
                type="submit"
                className="text-lg w-72 md:w-80 py-2 bg-neutral-200 font-semibold rounded-full mt-3"
              >
                Değiştir
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirmaIsim;
