import React, { useEffect, useState } from "react";
import { getCookieValue } from "../../services/getCookie";
import { sifreCoz } from "../../services/getPass";
import { db, storage } from "../../firebase";
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import {
  collection,
  getDocs,
  query,
  where,
  updateDoc,
  doc,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import imageCompression from "browser-image-compression";

const Urunler = () => {
  const [arananUrun, setArananUrun] = useState("");
  const [cozulmus, setCozulmus] = useState({});
  const [firmaKodu, setFirmaKodu] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [aktifGrup, setAktifGrup] = useState([]);

  const [menu, setMenu] = useState([]);
  const [filteredMenu, setFilteredMenu] = useState([]);
  const token = getCookieValue("token");
  const navigate = useNavigate();

  const handleFileChange = async (e, index) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const options = {
          maxSizeMB: 0.1,
          maxWidthOrHeight: 1000,
          useWebWorker: true,
        };
        const compressedFile = await imageCompression(file, options);
        // Create a reference to 'menu/firmaK/compressedFile.name'
        const kod = filteredMenu[index].kod;
        const kategori = filteredMenu[index].kategori;

        const imageRef = ref(storage, `${firmaKodu}/${kategori}/${kod}`);

        // Upload the compressed image
        await uploadBytes(imageRef, compressedFile);
        console.log('Upload successful!');

        // Get the download URL
        const downloadURL = await getDownloadURL(imageRef);
        console.log('File available at', downloadURL);

        // Update the menu item with the new image
        filteredMenu[index].resim = downloadURL;
        const newMenu = [...menu];
        setMenu(newMenu);
        setFilteredMenu(newMenu);

        // Güncel menüyü kaydet
        handleSave();
      } catch (error) {
        console.error("Resim yüklerken hata oluştu:", error);
        toast.error("Resim yüklerken hata oluştu.");
      }
    }
  };

  const removeImgFromStorage = async (index) => {
    try {
      const kod = filteredMenu[index].kod;
      const kategori = filteredMenu[index].kategori;

      const imageRef = ref(storage, `${firmaKodu}/${kategori}/${kod}`); // imagePath should be the full path to the image
      // Delete the file
      await deleteObject(imageRef);
    } catch (error) {
      console.error("Resim storageda silinirken ata oluştu:", error);
    }
  };

  const handleRemoveImage = async (index) => {
    try {
      filteredMenu[index].resim = null;
      const newMenu = [...menu];
      setMenu(newMenu);
      setFilteredMenu(newMenu);

      // Remove the image from storage
      await removeImgFromStorage(index);

      // Güncel menüyü kaydet
      handleSave();
    } catch (error) {
      console.error("Resim silinirken hata oluştu:", error);
      toast.error("Resim silinirken hata oluştu.");
    }
  };

  useEffect(() => {
    if (token) {
      const cozulmusVeri = sifreCoz(token);
      if (cozulmusVeri.firmaKod) {
        setFirmaKodu(cozulmusVeri.firmaKod);
      } 
      else {
      navigate("/");
    }
    } else {
      navigate("/");
    }
  }, [token, navigate]);

  useEffect(() => {
    const getMenu = async () => {
      try {
        const mRef = collection(db, "menu");
        const qM = query(mRef, where("firmaKod", "==", firmaKodu));
        const getM = await getDocs(qM);
        if (!getM.empty) {
          const getData = getM.docs[0].data();
          getData.id = getM.docs[0].id;
          setCozulmus(getData);
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (firmaKodu) {
      getMenu();
    }
  }, [firmaKodu]);

  useEffect(() => {
    const getGrups = async () => {
      try {
        if (firmaKodu) {
          const mRef = collection(db, "menu");
          const mQ = query(mRef, where("firmaKod", "==", firmaKodu));
          const getGroup = await getDocs(mQ);

          if (!getGroup.empty) {
            const data = getGroup.docs[0].data().urunGrup;
            setAktifGrup(data || []);
          } else {
            toast.error("Firma kodu geçersiz");
          }
        }
      } catch (error) {
        toast.error("Sistemde bir hata oluştu.");
        console.log(error);
      }
    };

    getGrups();
  }, [firmaKodu]);

  const handleSil = async (urunAd, index) => {
    try {
      if (
        !window.confirm(
          `"${urunAd}" adlı ürünü silmek istediğinizden emin misiniz?`
        )
      ) {
        return;
      }
      const mCollection = collection(db, "menu");
      const getQ = query(mCollection, where("firmaKod", "==", firmaKodu));
      const getMenus = await getDocs(getQ);

      if (!getMenus.empty) {
        const menuId = getMenus.docs[0].id;
        const docRef = doc(db, "menu", menuId);

        const menuData = getMenus.docs[0].data().menu;

        const updatedMenu = menuData.filter((item) => item.ad !== urunAd);

        setMenu(updatedMenu);

        await updateDoc(docRef, { menu: updatedMenu });

        await removeImgFromStorage(index);

        toast.success("Ürün başarıyla silindi.");
      } else {
        toast.error("Belirtilen firma koduna sahip menu bulunamadı.");
      }
    } catch (error) {
      console.log(error);
      toast.error("Bir hata oluştu: " + error.message);
    }
  };

  useEffect(() => {
    if (cozulmus.menu && Array.isArray(cozulmus.menu)) {
      const siralanmisYemek = cozulmus.menu.sort((a, b) => a.siraNo - b.siraNo);
      setMenu(siralanmisYemek);
      setFilteredMenu(siralanmisYemek);
    } else {
      setMenu([]);
      setFilteredMenu([]);
    }
  }, [cozulmus]);

  useEffect(() => {
    const filtered = arananUrun
      ? menu.filter((yemek) =>
          yemek.ad.toLowerCase().includes(arananUrun.toLowerCase())
        )
      : menu;
    setFilteredMenu(filtered);
  }, [arananUrun, menu]);

  const handleInputChange = (index, field, value) => {
    const updatedFilteredMenu = [...filteredMenu];
    const menuIndex = menu.findIndex(
      (item) => item.ad === updatedFilteredMenu[index].ad
    );
    updatedFilteredMenu[index] = {
      ...updatedFilteredMenu[index],
      [field]: value,
    };

    const updatedMenu = [...menu];
    updatedMenu[menuIndex] = { ...updatedMenu[menuIndex], [field]: value };

    setFilteredMenu(updatedFilteredMenu);
    setMenu(updatedMenu);
  };

  const handleSave = async () => {
    try {
      const docRef = doc(db, "menu", cozulmus.id);
      await updateDoc(docRef, { menu });
      toast.success("Menü başarıyla güncellendi!");
    } catch (error) {
      console.log("Error updating document: ", error);
      toast.error("Menü güncellenirken bir hata oluştu.");
    }
  };

  return (
    <div className="min-h-screen">
      <div>
        <img
          src={cozulmus.resimURL}
          className="w-40 pt-2 mx-auto"
          alt="Firma Resmi"
        />
        <p className="text-center text-4xl font-bold">{cozulmus.firmaAd}</p>
        <p className="text-xl mt-1 font-light text-center">ÜRÜNLER</p>
        <div className="w-full flex flex-col items-center justify-center">
          <select
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            className="py-2 mt-5 text-center w-80 md:w-2/6 px-2 bg-zinc-800 rounded-xl text-white text-lg font-semibold"
          >
            <option value="">Tüm Kategoriler</option>
            {aktifGrup.map((grup, index) => (
              <option className="rounded-xl" key={index} value={grup}>
                {grup}
              </option>
            ))}
          </select>
          <input
            type="text"
            value={arananUrun}
            onChange={(e) => setArananUrun(e.target.value)}
            placeholder="Ürün Ara"
            className="py-2 mt-2 placeholder-white text-center w-80 md:w-2/6 px-2 bg-zinc-800 rounded-xl text-white text-lg font-semibold"
          />
        </div>
        <div className="flex mt-2 items-center justify-center gap-2 pb-2 flex-col">
          {filteredMenu.length > 0 ? (
            filteredMenu.map((yemek, index) => (
              <>
                {selectedCategory !== "" ? (
                  <>
                    {yemek.kategori === selectedCategory && (
                      <div
                        key={index}
                        className="p-4 bg-neutral-100 flex items-center flex-col gap-1 justify-center md:w-2/6 w-80 rounded-xl"
                      >
                        {yemek.resim ? (
                          <>
                            <img alt="yemekResim" src={yemek.resim} className="w-full h-60" />
                            <button
                              onClick={() => handleRemoveImage(index)}
                              className="px-2 py-1 mt-2 bg-red-600 text-white rounded-xl text-lg"
                            >
                              Resmi Kaldır
                            </button>
                          </>
                        ) : (
                          <>
                            <input
                              type="file"
                              onChange={(e) => handleFileChange(e, index)}
                            />
                            <p>Ürün Resmi Yok</p>
                          </>
                        )}
                        <div className="flex items-center gap-3 w-full justify-between">
                          <p>Ürün Adı</p>
                          <input
                            type="text"
                            className="px-2 py-2 rounded-xl border placeholder-black text-lg w-auto md:w-80"
                            value={yemek.ad}
                            onChange={(e) =>
                              handleInputChange(index, "ad", e.target.value)
                            }
                          />
                        </div>
                        <div className="flex items-center gap-3 w-full justify-between">
                          <p>Ürün Açıklama</p>
                          <input
                            type="text"
                            className="px-2 py-2 rounded-xl border placeholder-black text-lg w-auto md:w-80"
                            value={yemek.aciklama}
                            onChange={(e) =>
                              handleInputChange(index, "aciklama", e.target.value)
                            }
                          />
                        </div>
                        <div className="flex items-center gap-3 w-full justify-between">
                          <p>Miktar</p>
                          <input
                            type="text"
                            className="px-2 py-2 rounded-xl border placeholder-black text-lg w-auto md:w-80"
                            value={yemek.miktar}
                            onChange={(e) =>
                              handleInputChange(index, "miktar", e.target.value)
                            }
                          />
                        </div>
                        <div className="flex items-center gap-3 w-full justify-between">
                          <p>Fiyat</p>
                          <input
                            type="number"
                            className="px-2 py-2 rounded-xl border placeholder-black text-lg w-auto md:w-80"
                            value={yemek.fiyat}
                            onChange={(e) =>
                              handleInputChange(index, "fiyat", e.target.value)
                            }
                          />
                        </div>
                        <div className="flex items-center gap-3 w-full justify-between">
                          <p>Sıra</p>
                          <input
                            type="number"
                            className="px-2 py-2 rounded-xl border placeholder-black text-lg w-auto md:w-80"
                            value={yemek.siraNo}
                            onChange={(e) =>
                              handleInputChange(index, "siraNo", e.target.value)
                            }
                          />
                        </div>
                        <button
                          onClick={() => handleSil(yemek.ad, index)}
                          className="px-2 w-full py-1 mt-1 bg-red-600 text-white rounded-xl text-lg"
                        >
                          Sil
                        </button>
                      </div>
                    )}
                  </>
                ) : (
                  <div
                    key={index}
                    className="p-4 bg-neutral-100 flex items-center flex-col gap-1 justify-center md:w-2/6 w-80 rounded-xl"
                  >
                    {yemek.resim ? (
                      <>
                        <img alt="yemekResim" src={yemek.resim} className="w-full h-60" />
                        <button
                          onClick={() => handleRemoveImage(index)}
                          className="px-2 py-1 mt-2 bg-red-600 text-white rounded-xl text-lg"
                        >
                          Resmi Kaldır
                        </button>
                      </>
                    ) : (
                      <>
                        <input
                          type="file"
                          onChange={(e) => handleFileChange(e, index)}
                        />
                        <p>Ürün Resim Yok</p>
                      </>
                    )}
                    <div className="flex items-center gap-3 w-full justify-between">
                      <p>Ürün Adı</p>
                      <input
                        type="text"
                        className="px-2 py-2 rounded-xl border placeholder-black text-lg w-auto md:w-80"
                        value={yemek.ad}
                        onChange={(e) =>
                          handleInputChange(index, "ad", e.target.value)
                        }
                      />
                    </div>
                    <div className="flex items-center gap-3 w-full justify-between">
                      <p>Ürün Açıklama</p>
                      <input
                        type="text"
                        className="px-2 py-2 rounded-xl border placeholder-black text-lg w-auto md:w-80"
                        value={yemek.aciklama}
                        onChange={(e) =>
                          handleInputChange(index, "aciklama", e.target.value)
                        }
                      />
                    </div>
                    <div className="flex items-center gap-3 w-full justify-between">
                      <p>Miktar</p>
                      <input
                        type="text"
                        className="px-2 py-2 rounded-xl border placeholder-black text-lg w-auto md:w-80"
                        value={yemek.miktar}
                        onChange={(e) =>
                          handleInputChange(index, "miktar", e.target.value)
                        }
                      />
                    </div>
                    <div className="flex items-center gap-3 w-full justify-between">
                      <p>Fiyat</p>
                      <input
                        type="number"
                        className="px-2 py-2 rounded-xl border placeholder-black text-lg w-auto md:w-80"
                        value={yemek.fiyat}
                        onChange={(e) =>
                          handleInputChange(index, "fiyat", e.target.value)
                        }
                      />
                    </div>
                    <button
                      onClick={() => handleSil(yemek.ad, index)}
                      className="px-2 w-full py-1 mt-1 bg-red-600 text-white rounded-xl text-lg"
                    >
                      Sil
                    </button>
                  </div>
                )}
              </>
            ))
          ) : (
            <p>Ürün bulunamadı</p>
          )}
          <button
            onClick={() => {
              navigate("/bayi/panel");
            }}
            className="fixed w-20 bottom-24 right-5 md:w-40 text-white py-2 bg-orange-500 rounded-xl text-lg font-bold"
          >
            Anasayfa
          </button>
          <button
            onClick={handleSave}
            className="fixed w-20 bottom-10 right-5 md:w-40 text-white py-2 bg-green-400 rounded-xl text-lg font-bold"
          >
            Kaydet
          </button>
        </div>
      </div>
    </div>
  );
};

export default Urunler;
