import React, { useState } from "react";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import "swiper/css/pagination";

const Container = ({ firmaB }) => {
  const [openSection, setOpenSection] = useState("");

  const toggleSection = (section) => {
    setOpenSection(openSection === section ? "" : section);
  };

  const ArrowIcon = ({ isOpen }) => (
    <span className="ml-2">
      {isOpen ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          viewBox="0 0 20 20"
          color="white"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M10 3a1 1 0 01.707.293l6 6a1 1 0 11-1.414 1.414L10 5.414 4.707 10.707a1 1 0 01-1.414-1.414l6-6A1 1 0 0110 3z"
            clipRule="evenodd"
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          viewBox="0 0 20 20"
          fill="currentColor"
          color="white"
        >
          <path
            fillRule="evenodd"
            d="M10 17a1 1 0 01-.707-.293l-6-6a1 1 0 011.414-1.414L10 14.586l5.293-5.293a1 1 0 011.414 1.414l-6 6A1 1 0 0110 17z"
            clipRule="evenodd"
          />
        </svg>
      )}
    </span>
  );

  return (
    <div className="w-80 md:w-2/6 py-2 px-2 rounded-lg mt-5">
      {firmaB.kampanyaDurum && (
        <div
          className={`p-3 ${
            firmaB.kampanya && "animate-pulse"
          } bg-orange-400 rounded`}
        >
          <div
            onClick={() => toggleSection("kampanyalar")}
            className="flex justify-between items-center cursor-pointer"
          >
            <p className="text-white font-semibold">Kampanyalarımız</p>
            <ArrowIcon isOpen={openSection === "kampanyalar"} />
          </div>
          {openSection === "kampanyalar" && (
            <div className="mt-2 pt-4 px-2 bg-white rounded">
              {firmaB.kampanya ? (
                <Swiper
                  modules={[Pagination]}
                  pagination={{ clickable: true }}
                  spaceBetween={10}
                  slidesPerView={2}
                >
                  {firmaB.kampanya.reverse().map((donen, index) => (
                    <SwiperSlide className="p-4 mb-8 bg-gray-100" key={index}>
                      <div>
                        <p className="font-bold">{donen.baslik}</p>
                        <p>{donen.metin}</p>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              ) : (
                <p className="pb-4">Aktif Kampanya Bulunamadı.</p>
              )}
            </div>
          )}
        </div>
      )}
      {firmaB.duyuruDurum && (
        <div
          className={`p-3 mt-4 ${
            firmaB.duyuru && "animate-pulse"
          } bg-green-400 rounded`}
        >
          <div
            onClick={() => toggleSection("duyurular")}
            className="flex justify-between items-center cursor-pointer"
          >
            <p className="text-white font-semibold">Duyurular</p>
            <ArrowIcon isOpen={openSection === "duyurular"} />
          </div>
          {openSection === "duyurular" && (
            <div className="mt-2 pt-4 px-2 bg-white rounded">
              {firmaB.duyuru ? (
                <Swiper
                  modules={[Pagination]}
                  pagination={{ clickable: true }}
                  spaceBetween={10}
                  slidesPerView={1}
                >
                  {firmaB.duyuru.reverse().map((donen, index) => (
                    <SwiperSlide className="p-4 mb-8 bg-slate-100" key={index}>
                      <div>
                        <p className="font-bold">{donen.baslik}</p>
                        <p>{donen.metin}</p>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              ) : (
                <p className="pb-4">Duyuru Bulunamadı.</p>
              )}
            </div>
          )}
        </div>
      )}
      {firmaB.hakkimdaDurum && (
        <div className="p-3 mt-4 bg-blue-400 rounded">
          <div
            onClick={() => toggleSection("hakkimizda")}
            className="flex justify-between items-center cursor-pointer"
          >
            <p className="text-white font-semibold">Hakkımızda</p>
            <ArrowIcon isOpen={openSection === "hakkimizda"} />
          </div>
          {openSection === "hakkimizda" && (
            <div className="mt-2 bg-white p-2 rounded">
              <p className="text-gray-600 text-base">
                {firmaB.hakkimizda ? (
                  firmaB.hakkimizda
                ) : (
                  <p>Hakkımızda Metni Bulunamadı.</p>
                )}
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Container;
