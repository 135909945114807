import React, { useEffect, useState } from "react";
import logo from "../../assets/img/logo.png";
import "../../assets/style/tooltip.css";
import { useParams } from "react-router-dom";
import { db } from "../../firebase";
import { collection, getDocs, query, where } from "firebase/firestore";
import ErrorModal from "../../modals/ErrorModal";
import SModal from "../../modals/ShareModal";
import GarsonM from "../../modals/GarsonModal";
import Container from "../../components/home/Container";
import gsvg from "../../assets/img/google.png";
import toast from "react-hot-toast";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";

const Home = () => {
  const { firma } = useParams();
  const [selectedCategory, setSelectedCategory] = useState("bo");
  const [gelenBilgi, setGelenBilgi] = useState([]);
  const [errModal, setErrModal] = useState(false);
  const [garsonModal, setGarsonModal] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const [uyelikBitti, setUyelikBitti] = useState(false);
  const [modalAcik, setModalAcik] = useState(false);
  const [bilgiYok, setBilgiYok] = useState(false);
  const [aktifGrup, setAktifGrup] = useState([]);

  useEffect(() => {
    const getMenu = async () => {
      try {
        const menuCollection = collection(db, "menu");
        const qMenu = query(menuCollection, where("firmaKod", "==", firma));
        const menuSnap = await getDocs(qMenu);

        const menuData = [];
        menuSnap.forEach((donenDoc) => {
          menuData.push({
            id: donenDoc.id,
            ...donenDoc.data(),
          });
        });
        if (menuData.length === 0) {
          setBilgiYok(true);
        } else {
          const durum = menuSnap.docs[0].data().durum;
          if (!durum) {
            setBilgiYok(true);
          } else {
            setGelenBilgi(menuData);
          }
        }
      } catch (error) {
        toast.error("Sistemde bir hata oluştu.");
        console.log(error);
      }
    };

    getMenu();
  }, [firma]);

  useEffect(() => {
    if (gelenBilgi && gelenBilgi.length > 0) {
      const bilgi = gelenBilgi[0];
      if (bilgi.bitisTarih) {
        const bitisTarih = bilgi.bitisTarih.toDate();
        const simdikiTarih = new Date();
        const farkMs = bitisTarih - simdikiTarih;
        const gunFarki = Math.ceil(farkMs / (1000 * 60 * 60 * 24));
        if (gunFarki <= 0) {
          setBilgiYok(true);
          setUyelikBitti(true);
        }
      }
    }
  }, [gelenBilgi]);

  useEffect(() => {
    const getGrups = async () => {
      try {
        if (firma) {
          const mRef = collection(db, "menu");
          const mQ = query(mRef, where("firmaKod", "==", firma));
          const getGroup = await getDocs(mQ);

          if (!getGroup.empty) {
            const data = getGroup.docs[0].data().urunGrup;
            setAktifGrup(data || []);
          } else {
            toast.error("Firma kodu geçersiz");
          }
        }
      } catch (error) {
        toast.error("Sistemde bir hata oluştu.");
        console.log(error);
      }
    };

    getGrups();
  }, [firma]);

  const siralanmisYemek = gelenBilgi?.map((firma) => ({
    ...firma,
    menu: firma.menu?.sort((a, b) => a.siraNo - b.siraNo),
  }));
  
  const handleDownloadVCF = () => {
    if (gelenBilgi.length === 0) return;

    const bilgi = gelenBilgi[0];
    const firmaAd = bilgi.firmaAd || "-";
    const tel = bilgi.tel || "-";
    const sokak = bilgi.tamAdres?.sokak || "-";
    const mahalle = bilgi.tamAdres?.mahalle || "-";
    const ilce = bilgi.tamAdres?.ilce || "-";
    const il = bilgi.tamAdres?.il || "-";
    const wpTel = bilgi.wpTel || "-";

    const vcardContent = `
          BEGIN:VCARD
          VERSION:3.0
          N:${firmaAd}
          FN:${il}
          TEL;TYPE=WORK,VOICE:${wpTel}
          TEL;TYPE=CELL,VOICE:${tel}
          ADR;TYPE=WORK:;;${sokak} ${mahalle};${ilce};${il};;;
          ORG:${firmaAd}
          URL;TYPE=WORK:minimenu.com.tr/${firma}
          END:VCARD
          `.trim();

    const blob = new Blob([vcardContent], { type: "text/vcard" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${firmaAd}.vcf`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <div className="min-h-screen flex flex-col">
      <div className="flex items-center mt-5 justify-evenly">
        <img src={logo} className="w-28 p-2 bg-black rounded-xl" />
        <p className="font-semibold text-red-600 text-lg">{firma}</p>
      </div>
      {bilgiYok ? (
        <div className="flex flex-col mt-20 gap-3 items-center justify-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={60}
            height={60}
            color={"#000000"}
            fill={"none"}
          >
            <path
              d="M17 14.998V16.998M17.009 18.998H17M22 16.998C22 19.7594 19.7614 21.998 17 21.998C14.2386 21.998 12 19.7594 12 16.998C12 14.2366 14.2386 11.998 17 11.998C19.7614 11.998 22 14.2366 22 16.998Z"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
            />
            <path
              d="M20.232 10.054C20.712 9.51405 21.54 8.69805 21.4958 8.36573C21.5298 8.04251 21.3548 7.73888 21.0049 7.13163L20.5114 6.27503C20.1381 5.6272 19.9514 5.30328 19.6338 5.17412C19.3163 5.04495 18.9571 5.14688 18.2388 5.35072L17.0185 5.69442C16.5599 5.80017 16.0788 5.74018 15.66 5.52503L15.3231 5.33066C14.9641 5.10067 14.6879 4.76157 14.535 4.36298L14.201 3.36559C13.9815 2.70558 13.8717 2.37557 13.6103 2.18681C13.3489 1.99805 13.0017 1.99805 12.3074 1.99805H11.1926C10.4982 1.99805 10.151 1.99805 9.88973 2.18681C9.62833 2.37557 9.51853 2.70558 9.29893 3.36559L8.96503 4.36298C8.81213 4.76157 8.53593 5.10067 8.17683 5.33066L7.83993 5.52503C7.42123 5.74018 6.94003 5.80017 6.48143 5.69442L5.26124 5.35072C4.54294 5.14688 4.18374 5.04495 3.86614 5.17412C3.54854 5.30328 3.36194 5.6272 2.98864 6.27503L2.49504 7.13163C2.14515 7.73888 1.97025 8.04251 2.00415 8.36573C2.03815 8.68895 2.27235 8.94942 2.74074 9.47036L3.77184 10.623C4.02374 10.942 4.20274 11.498 4.20274 11.9978C4.20274 12.498 4.02384 13.0538 3.77184 13.3729L2.74074 14.5256C2.27235 15.0465 2.03815 15.307 2.00415 15.6302C1.97025 15.9535 2.14515 16.2571 2.49504 16.8643L2.98864 17.7209C3.36194 18.3687 3.54854 18.6927 3.86614 18.8218C4.18374 18.951 4.54294 18.8491 5.26124 18.6452L6.48143 18.3015C6.94013 18.1957 7.42133 18.2558 7.84013 18.471L8.17693 18.6654C8.53603 18.8954 8.81213 19.2344 8.96493 19.633L9.29893 20.6305C9.51853 21.2905 9.63993 21.6336 9.84 21.778C9.9 21.8214 10.14 22.018 10.728 22"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
            />
            <path
              d="M14.1599 9.44605C13.3199 8.76205 12.6599 8.49805 11.6999 8.49805C9.89986 8.52205 8.25586 10.009 8.25586 11.942C8.25586 13.0077 8.57986 13.682 9.19186 14.39"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
            />
          </svg>
          {uyelikBitti ? (
            <p>
              Firma Menüsü <span className="font-bold">Pasif</span> Duruma
              Getirildi.
            </p>
          ) : (
            <div>
              <p className="text-xl font-bold">Firma Bulunamadı.</p>
              <p className="text-center text-lg font-bold text-gray-600">
                Bulundugunuz Lokanta/Cafe'deki bir yetkiliden yardım isteyiniz.
              </p>
            </div>
          )}
        </div>
      ) : (
        <div>
          {gelenBilgi ? (
            <div className="mb-20">
              {siralanmisYemek.map((firma, index) => (
                <div
                  key={index}
                  className="flex flex-col items-center justify-center"
                >
                  <img
                    src={firma.resimURL}
                    className="w-32 mt-3 rounded-xl md:mt-0 md:w-40 h-auto"
                  />
                  <p className="text-3xl text-center font-semibold">
                    {firma.firmaAd}
                  </p>

                  <p className="text-md font-bold text-gray-600">{firma.yer}</p>
                  {firma ? (
                    <>
                      {firma.isletmeResim && firma.isletmeResim.length > 0 && (
                        <div className="w-[320px] h-[240px] mt-5">
                          <Swiper
                            slidesPerView={1}
                            autoplay={{
                              delay: 2500,
                              disableOnInteraction: false,
                            }}
                            modules={[Pagination]}
                            pagination={{
                              clickable: true,
                            }}
                            className="mx-auto h-[200px] bg-gray-100"
                          >
                            {firma.isletmeResim.map((data, index) => (
                              <SwiperSlide key={index}>
                                <img
                                  src={data.resim}
                                  className="bg-cover h-full w-full rounded-xl"
                                />
                              </SwiperSlide>
                            ))}
                          </Swiper>
                        </div>
                      )}
                      <div className="w-80 md:w-2/6 flex flex-col items-center mt-2 gap-2 justify-center">
                        <select
                          value={selectedCategory}
                          onChange={(e) => setSelectedCategory(e.target.value)}
                          className="mt-4 w-full rounded-xl bg-zinc-800 text-white font-bold py-4 outline-none px-2 border"
                        >
                          <option value={"bo"}>
                            Menü İçin Kategori Seçiniz
                          </option>
                          {aktifGrup.length > 0
                            ? aktifGrup.map((grup, index) => (
                                <option
                                  className="rounded-xl"
                                  key={index}
                                  value={grup}
                                >
                                  {grup}
                                </option>
                              ))
                            : ""}
                          <option value="">Tüm Kategoriler</option>
                        </select>
                        {firma.menu ? (
                          firma.menu.map((yemek, index) =>
                            selectedCategory === "" ||
                            yemek.kategori === selectedCategory ? (
                              <div
                                key={index}
                                className="w-full flex flex-col items-center justify-between gap-2 py-2 px-2 bg-neutral-200 shadow-2xl border-b-2 rounded-xl border-neutral-200 border-r-2"
                              >
                                {yemek.resim && (
                                  <img
                                    src={yemek.resim}
                                    className="w-[320px] rounded-2xl h-[240px]"
                                  />
                                )}

                                <p className="font-bold w-full py-1 rounded-full text-center text-lg">
                                  {yemek.ad}
                                </p>
                                <div className="flex w-full items-center mt-2 md:mt-0 gap-2">
                                  <p className="w-full text-center flex-1 font-semibold text-lg">
                                    {yemek.miktar}
                                  </p>
                                  <p className="bg-red-500 text-white flex-1 w-full text-center font-semibold text-lg rounded-full">
                                    {yemek.fiyat} ₺
                                  </p>
                                </div>
                                <div className="w-full">
                                  {yemek.aciklama && (
                                    <p className="text-sm w-full text-center text-gray-700 py-1 rounded-xl border ">
                                      {yemek.aciklama}
                                    </p>
                                  )}
                                </div>
                              </div>
                            ) : null
                          )
                        ) : (
                          <p>Menüde Ürün Yok.</p>
                        )}
                      </div>
                    </>
                  ) : null}
                  {selectedCategory === "bo" ? (
                    <Container firmaB={firma} />
                  ) : null}
                  <div className="flex bg-white py-2 px-2 rounded-xl items-center justify-center gap-5 fixed bottom-5">
                    <a 
                    href={firma.yorumlar} 
                    target="_blank"
                    rel="noopener noreferrer" // For security when opening new tabs
                    className="tooltip"
                    >
                      <img src={gsvg} className="size-10" />
                      <span className="tooltiptext">
                        Google yorumlarını görebilirsiniz.
                      </span>
                    </a>
                    <a
                      href={firma.mapsKonum ? `https://www.google.com/maps?q=${firma.mapsKonum.latitude},${firma.mapsKonum.longitude}` : undefined}
                      target="_blank"
                      rel="noopener noreferrer" // For security when opening new tabs
                      className="tooltip"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="40"
                        height="40"
                        color="#000000"
                        fill="none"
                        className="cursor-pointer hover:scale-105 active:scale-110"
                      >
                        <path
                          d="M14.5 10C14.0697 8.55426 12.5855 7.5 11 7.5C9.067 7.5 7.5 9.067 7.5 11C7.5 12.7632 8.80385 14.2574 10.5 14.5"
                          stroke="currentColor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                        ></path>
                        <path
                          d="M19.9504 10C19.4697 5.53446 15.5596 2 11 2C6.12944 2 2 6.03298 2 10.9258C2 15.9137 6.2039 19.3616 10.073 21.7567C10.3555 21.9162 10.675 22 11 22C11.325 22 11.6445 21.9162 11.927 21.7567C12.1816 21.6009 12.4376 21.4403 12.6937 21.2748"
                          stroke="currentColor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                        ></path>
                        <path
                          d="M17.5 12C19.9353 12 22 14.0165 22 16.4629C22 18.9482 19.9017 20.6924 17.9635 21.8783C17.8223 21.9581 17.6625 22 17.5 22C17.3375 22 17.1777 21.9581 17.0365 21.8783C15.1019 20.6808 13 18.9568 13 16.4629C13 14.0165 15.0647 12 17.5 12Z"
                          stroke="currentColor"
                          stroke-width="1.5"
                        ></path>
                        <path
                          d="M17.5 16.5H17.509"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                      <span className="tooltiptext">
                        Konum Uygulamasına Yönlendirir.
                      </span>
                    </a>
                    <button className="tooltip">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width={40}
                        height={40}
                        onClick={handleDownloadVCF}
                        className="cursor-pointer hover:scale-105 active:scale-110"
                        color={"#000000"}
                        fill={"none"}
                      >
                        <path
                          d="M4 17.9808V9.70753C4 6.07416 4 4.25748 5.17157 3.12874C6.34315 2 8.22876 2 12 2C15.7712 2 17.6569 2 18.8284 3.12874C20 4.25748 20 6.07416 20 9.70753V17.9808C20 20.2867 20 21.4396 19.2272 21.8523C17.7305 22.6514 14.9232 19.9852 13.59 19.1824C12.8168 18.7168 12.4302 18.484 12 18.484C11.5698 18.484 11.1832 18.7168 10.41 19.1824C9.0768 19.9852 6.26947 22.6514 4.77285 21.8523C4 21.4396 4 20.2867 4 17.9808Z"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M4 7H20"
                          stroke="currentColor"
                          strokeWidth="1.5"
                        />
                      </svg>
                      <span className="tooltiptext">
                        Firma Adresi ve Telefon Numarasını indirebilirsiniz.
                      </span>
                    </button>
                    <button className="tooltip">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width={40}
                        onClick={() =>
                          window.open(`https://wa.me/${firma.tel ? firma.tel : firma.wpTel}?text=Sipariş%20vermek%20istiyorum.`,
                          '_blank',
                          'noopener,noreferrer')
                        }
                        className="cursor-pointer hover:scale-105 active:scale-110"
                        height={40}
                        color={"#000000"}
                        fill={"none"}
                      >
                        <path
                          d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 13.3789 2.27907 14.6926 2.78382 15.8877C3.06278 16.5481 3.20226 16.8784 3.21953 17.128C3.2368 17.3776 3.16334 17.6521 3.01642 18.2012L2 22L5.79877 20.9836C6.34788 20.8367 6.62244 20.7632 6.87202 20.7805C7.12161 20.7977 7.45185 20.9372 8.11235 21.2162C9.30745 21.7209 10.6211 22 12 22Z"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M8.58815 12.3773L9.45909 11.2956C9.82616 10.8397 10.2799 10.4153 10.3155 9.80826C10.3244 9.65494 10.2166 8.96657 10.0008 7.58986C9.91601 7.04881 9.41086 7 8.97332 7C8.40314 7 8.11805 7 7.83495 7.12931C7.47714 7.29275 7.10979 7.75231 7.02917 8.13733C6.96539 8.44196 7.01279 8.65187 7.10759 9.07169C7.51023 10.8548 8.45481 12.6158 9.91948 14.0805C11.3842 15.5452 13.1452 16.4898 14.9283 16.8924C15.3481 16.9872 15.558 17.0346 15.8627 16.9708C16.2477 16.8902 16.7072 16.5229 16.8707 16.165C17 15.8819 17 15.5969 17 15.0267C17 14.5891 16.9512 14.084 16.4101 13.9992C15.0334 13.7834 14.3451 13.6756 14.1917 13.6845C13.5847 13.7201 13.1603 14.1738 12.7044 14.5409L11.6227 15.4118"
                          stroke="currentColor"
                          strokeWidth="1.5"
                        />
                      </svg>
                      <span className="tooltiptext">
                        WhatsApp'tan firma ile iletişime geçebilirsiniz.
                      </span>
                    </button>
                    <button className="tooltip">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        onClick={() => {
                          window.open(`tel:${firma.wpTel ? firma.wpTel : firma.tel}`,
                          '_blank',
                          'noopener,noreferrer');
                        }}
                        width={40}
                        height={40}
                        className="cursor-pointer hover:scale-105 active:scale-110"
                        color={"#000000"}
                        fill={"none"}
                      >
                        <path
                          d="M3.77762 11.9424C2.8296 10.2893 2.37185 8.93948 2.09584 7.57121C1.68762 5.54758 2.62181 3.57081 4.16938 2.30947C4.82345 1.77638 5.57323 1.95852 5.96 2.6524L6.83318 4.21891C7.52529 5.46057 7.87134 6.08139 7.8027 6.73959C7.73407 7.39779 7.26737 7.93386 6.33397 9.00601L3.77762 11.9424ZM3.77762 11.9424C5.69651 15.2883 8.70784 18.3013 12.0576 20.2224M12.0576 20.2224C13.7107 21.1704 15.0605 21.6282 16.4288 21.9042C18.4524 22.3124 20.4292 21.3782 21.6905 19.8306C22.2236 19.1766 22.0415 18.4268 21.3476 18.04L19.7811 17.1668C18.5394 16.4747 17.9186 16.1287 17.2604 16.1973C16.6022 16.2659 16.0661 16.7326 14.994 17.666L12.0576 20.2224Z"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <span className="tooltiptext">
                        Tıklayarak Sipariş No Arayabilirsiniz.
                      </span>
                    </button>
                  </div>
                  <button
                    onClick={() => {
                      setGarsonModal(true);
                    }}
                    className="fixed hidden justify-center bg-yellow-200 py-2 w-28  items-center rounded-r-xl gap-2 bottom-20 md:bottom-10 left-0"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width={16}
                      height={16}
                      color={"black"}
                      fill={"none"}
                    >
                      <path
                        d="M20 22.0017V16.0275C20 15.0786 20 14.6042 19.8416 14.2303C19.6413 13.7575 19.2669 13.3798 18.7958 13.1755C18.4232 13.0139 17.9488 13.0098 17 13.0017C17 18.0017 12 20.0017 12 20.0017C12 20.0017 7 18.0017 7 13.0017C6.06812 13.0017 5.60218 13.0017 5.23463 13.1539C4.74458 13.3569 4.35523 13.7463 4.15224 14.2363C4 14.6039 4 15.0698 4 16.0017V22.0017"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12 13.5L14 12.5V14.5L12 13.5ZM12 13.5L10 12.5002V14.5002L12 13.5Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M15.5 6.5V5.5C15.5 3.567 13.933 2 12 2C10.067 2 8.5 3.567 8.5 5.5V6.5C8.5 8.433 10.067 10 12 10C13.933 10 15.5 8.433 15.5 6.5Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <span className="text-sm   font-semibold">
                      Garson Çağır
                    </span>
                  </button>
                </div>
              ))}
            </div>
          ) : (
            <p className="text-9xl">Yükleniyor..</p>
          )}
        </div>
      )}
      {errModal && <ErrorModal />}
      {garsonModal && <GarsonM />}
      {shareModal && <SModal kod={firma} />}
      {modalAcik && (
        <button
          onClick={() => {
            if (errModal) {
              setErrModal(false);
              setModalAcik(false);
            }
            if (shareModal) {
              setShareModal(false);
              setModalAcik(false);
            }
          }}
          className="fixed bottom-20 md:bottom-64 left-1/2 transform -translate-x-1/2 bg-red-400 w-72 py-3 rounded-full font-bold text-white z-50"
        >
          Kapat
        </button>
      )}
    </div>
  );
};

export default Home;
