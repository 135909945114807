import { addDoc, collection } from "firebase/firestore";
import React, { useState } from "react";
import { db } from "../firebase";
import toast from "react-hot-toast";

const ErrorModal = () => {
  const [metin, setMetin] = useState("");

  const handleBildir = async (e) => {
    e.preventDefault();
    try {
      const ref = collection(db, "hata");

      await addDoc(ref, { metin });

      toast.success("Bildirim gönderildi.");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="fixed z-50 font-manrope inset-0 bg-neutral-800 bg-opacity-70 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen">
        <div className="w-80 md:w-2/6 py-4 bg-white flex flex-col items-center justify-center rounded-xl">
          <p className="text-lg font-bold tracking-wide">Hata Bildir!</p>
          <form className="flex items-center flex-col justify-center">
            <textarea
              type="text"
              placeholder="Hata Nedir?"
              value={metin}
              onChange={(e) => setMetin(e.target.value)}
              className="w-72 md:w-96 py-2 px-2 bg-neutral-200 mt-2 shadow rounded-xl"
              required
            />
            <button
              type="submit"
              onClick={handleBildir}
              className="py-2 mt-2 w-72 md:w-96 active:scale-105 bg-neutral-300 shadow rounded-full font-bold text-md"
            >
              Bildir
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ErrorModal;
