import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import React, { useState, useEffect } from "react";
import { db } from "../../firebase";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import toast from "react-hot-toast";

const KulKontrol = () => {
  const [firmaK, setFirmaK] = useState("");
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [dayCount, setDayCount] = useState(0);

  const handleGetir = async (e) => {
    e.preventDefault();
    setLoading(true);
    setResults([]);
    try {
      const ref = collection(db, "menu");
      const q = query(ref, where("firmaKod", "==", firmaK));
      const querySnapshot = await getDocs(q);
      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        bitisTarih: doc.data().bitisTarih
          ? doc.data().bitisTarih.toDate()
          : null,
      }));
      setResults(data);
      if (data.length === 0) {
        toast.error("Firma Kodu Geçersiz.");
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
      toast.error("Firma Kodu Geçersiz.");
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (date) => {
    const options = { day: "numeric", month: "long", year: "numeric" };
    return new Date(date).toLocaleDateString("tr-TR", options);
  };

  const handleSil = async (id) => {
    try {
      await deleteDoc(doc(db, "menu", id));
      setResults(results.filter((result) => result.id !== id));
      toast.success("Firma başarıyla silindi.");
      window.location.reload();
    } catch (error) {
      console.error("hata: ", error);
      toast.error("Firma silinirken bir hata oluştu.");
    }
  };

  const handleEditToggle = async (index) => {
    const updatedResults = [...results];
    if (updatedResults[index].isEditing) {
      const docRef = doc(db, "menu", updatedResults[index].id);

      await updateDoc(docRef, {
        firmaAd: updatedResults[index].firmaAd,
        kullanici: updatedResults[index].kullanici,
        sifre: updatedResults[index].sifre,
        tel: updatedResults[index].tel,
        durum: updatedResults[index].durum,
        createdAt: selectedDate,
        bitisTarih: updatedResults[index].bitisTarih,
      });
    }
    updatedResults[index].isEditing = !updatedResults[index].isEditing;
    setResults(updatedResults);
  };

  const handleInputChange = (index, field, value) => {
    const updatedResults = [...results];
    if (field === "durum") {
      updatedResults[index][field] = value === "aktif";
    } else if (field === "bitisTarih") {
      updatedResults[index][field] = value;
    } else {
      updatedResults[index][field] = value;
    }
    setResults(updatedResults);
  };

  useEffect(() => {
    setSelectedDate(new Date());
  }, []);

  return (
    <div className="w-full 2xl:px-96 mt-3">
      <div className="flex flex-col gap-2 border px-1 py-2 justify-center rounded-xl items-center">
        <p className="text-2xl md:text-3xl font-bold">Kullanıcı Ara</p>
        <form onSubmit={handleGetir} className="flex items-center">
          <input
            type="text"
            value={firmaK}
            onChange={(e) => setFirmaK(e.target.value)}
            className="w-72 md:w-96 py-4 outline-none text-white shadow-md bg-zinc-800 placeholder-gray-300 px-2 text-xl font-bold rounded-l-3xl"
            placeholder="Firma Kodu"
            required
          />
          <button
            type="submit"
            className="w-20 py-4 bg-zinc-700 shadow-md text-white text-xl font-bold rounded-r-3xl"
          >
            ARA
          </button>
        </form>
        {loading ? (
          <p className="text-xl mt-2">Yükleniyor...</p>
        ) : (
          <div className="mt-2">
            {results.length > 0 ? (
              <ul>
                {results.map((result, index) => (
                  <li className="text-center flex flex-col gap-2" key={index}>
                    <p className="text-xl font-semibold">
                      <span className="font-extrabold">{result.firmaKod}</span>{" "}
                      Kodlu Firma
                    </p>
                    <img
                      src={result.resimURL}
                      className="w-40 mx-auto rounded-xl"
                      alt="Firma Resmi"
                    />
                    <div className="text-lg">
                      Firma Adı{" "}
                      {result.isEditing ? (
                        <input
                          type="text"
                          value={result.firmaAd ? result.firmaAd : "-"}
                          className="py-1 bg-neutral-200 border rounded-xl px-2 font-bold"
                          onChange={(e) =>
                            handleInputChange(index, "firmaAd", e.target.value)
                          }
                        />
                      ) : (
                        <span className="font-bold"> {result.firmaAd}</span>
                      )}
                    </div>
                    <div className="text-lg">
                      Kullanıcı Adı{" "}
                      {result.isEditing ? (
                        <input
                          type="text"
                          className="py-1 bg-neutral-200 border rounded-xl px-2 font-bold"
                          value={result.kullanici}
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "kullanici",
                              e.target.value
                            )
                          }
                        />
                      ) : (
                        <span className="font-bold">{result.kullanici}</span>
                      )}
                    </div>
                    <div className="text-lg w-80">
                      Şifre{" "}
                      {result.isEditing ? (
                        <input
                          type="text"
                          className="py-1 bg-neutral-200 border rounded-xl px-2 font-bold"
                          value={result.sifre}
                          onChange={(e) =>
                            handleInputChange(index, "sifre", e.target.value)
                          }
                        />
                      ) : (
                        <span className="font-bold">{result.sifre}</span>
                      )}
                    </div>
                    <div className="text-lg w-80">
                      Telefon{" "}
                      {result.isEditing ? (
                        <input
                          type="text"
                          className="py-1 bg-neutral-200 border rounded-xl px-2 font-bold"
                          value={result.tel ? result.tel : "-"}
                          onChange={(e) =>
                            handleInputChange(index, "tel", e.target.value)
                          }
                        />
                      ) : (
                        <span className="font-bold">{result.tel}</span>
                      )}
                    </div>
                    <div className="text-lg w-80">
                      Üyelik Başlangıç
                      {result.isEditing ? (
                        <DatePicker
                          selected={selectedDate}
                          onChange={(date) => setSelectedDate(date)}
                          dateFormat="dd/MM/yyyy"
                          className="py-1 bg-neutral-200 border rounded-xl px-2 font-bold"
                        />
                      ) : (
                        <span className="font-bold">
                          {" "}
                          {result.createdAt
                            ? formatDate(result.createdAt.toDate())
                            : "-"}
                        </span>
                      )}
                    </div>
                    <div className="text-lg w-80">
                      Bitiş{" "}
                      {result.isEditing ? (
                        <div>
                          <DatePicker
                            selected={
                              result.bitisTarih ? result.bitisTarih : null
                            }
                            onChange={(date) =>
                              handleInputChange(index, "bitisTarih", date)
                            }
                            dateFormat="dd/MM/yyyy"
                            className="py-1 bg-neutral-200 border rounded-xl px-2 font-bold"
                          />
                          <input
                            type="number"
                            className="py-1 bg-neutral-200 border rounded-xl px-2 font-bold mt-2"
                            value={dayCount}
                            onChange={(e) =>
                              setDayCount(Number(e.target.value))
                            }
                            placeholder="Gün Sayısı"
                          />
                        </div>
                      ) : (
                        <span className="font-bold">
                          {" "}
                          {result.bitisTarih
                            ? formatDate(result.bitisTarih)
                            : "-"}
                        </span>
                      )}
                    </div>
                    <div className="text-lg w-80">
                      Durum{" "}
                      {result.isEditing ? (
                        <select
                          value={result.durum ? "aktif" : "pasif"}
                          className="py-1 bg-neutral-200 border rounded-xl px-2 font-bold"
                          onChange={(e) =>
                            handleInputChange(index, "durum", e.target.value)
                          }
                        >
                          <option value="aktif">Aktif</option>
                          <option value="pasif">Pasif</option>
                        </select>
                      ) : (
                        <span className="font-bold">
                          {result.durum ? "Aktif" : "Pasif"}
                        </span>
                      )}
                    </div>
                    <button
                      onClick={() => handleEditToggle(index)}
                      className="bg-blue-500 text-white px-4 py-2 rounded-md"
                    >
                      {result.isEditing ? "Kaydet" : "Düzenle"}
                    </button>
                    <button
                      onClick={() => handleSil(result.id)}
                      className="bg-red-500 text-white px-4 py-2 rounded-md"
                    >
                      Sil
                    </button>
                  </li>
                ))}
              </ul>
            ) : (
              <p className="text-xl">Sonuç Bulunamadı</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default KulKontrol;
