import React, { useEffect, useState } from "react";
import { db } from "../../firebase";
import { collection, getDocs, query, where } from "firebase/firestore";

const TumKullanici = () => {
  const [kullanicilar, setKullanicilar] = useState([]);
  const [filteredKullanicilar, setFilteredKullanicilar] = useState([]);
  const [durum, setDurum] = useState("Durum Seç");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const durumKontrol = (kullanici) => {
    const endDate = (kullanici.bitisTarih).toDate();
    const now = new Date();
    console.log(endDate, now);
    if (endDate > now) {
      return "Aktif";
    } else {
      return "Pasif";
    }
  };

  useEffect(() => {
    const getKullanicilar = async () => {
      try {
        const kulRef = collection(db, "menu");
        let q = query(kulRef);

        if (startDate && endDate) {
          q = query(
            kulRef,
            where("stamp", ">=", new Date(startDate)),
            where("stamp", "<=", new Date(endDate))
          );
        }

        const querySnapshot = await getDocs(q);
        const kullanicilarArr = [];
        querySnapshot.forEach((doc) => {
          kullanicilarArr.push({ id: doc.id, ...doc.data() });
        });

        // Firma koduna göre sıralama
        kullanicilarArr.sort((a, b) => {
          const aFirmaKod = a.firmaKod.toUpperCase();
          const bFirmaKod = b.firmaKod.toUpperCase();
          return aFirmaKod.localeCompare(bFirmaKod);
        });

        setKullanicilar(kullanicilarArr);
        setFilteredKullanicilar(kullanicilarArr);
      } catch (error) {
        console.error("Hata: ", error);
      }
    };

    getKullanicilar();
  }, [startDate, endDate]);

  useEffect(() => {
    let filteredList = kullanicilar;

    if (durum !== "Durum Seç") {
      const durumBool = durum === "Aktif";
      filteredList = filteredList.filter(
        (kullanici) => kullanici.durum === durumBool
      );
    }

    setFilteredKullanicilar(filteredList);
  }, [durum, kullanicilar]);

  const formatDate = (date) => {
    const options = { day: "numeric", month: "long", year: "numeric" };
    return new Date(date).toLocaleDateString("tr-TR", options);
  };

  const downloadCSV = () => {
    const csvRows = [];
    const headers = [
      "Firma Kodu",
      "Firma Adi",
      "Kullanıcı Adı",
      "Sifre",
      "Adres",
      "Unvan",
      "Telefon Numarasi",
      "Tam Adres",
      "Uyelik Baslangic",
      "Uyelik Bitis",
      "Konum (Latitude)",
      "Konum (Longitude)",
    ];

    csvRows.push(headers.join(";"));

    filteredKullanicilar.forEach((kullanici) => {
      const rowData = [
        kullanici.firmaKod ? kullanici.firmaKod : "-",
        kullanici.firmaAd ? kullanici.firmaAd : "-",
        kullanici.kullanici || "-",
        kullanici.sifre || "-",
        kullanici.yer || "-",
        kullanici.unvan || "-",
        kullanici.tel || "-",
        kullanici.tamAdres || "-",
        kullanici.createdAt ? formatDate(kullanici.stamp.toDate()) : "-",
        kullanici.bitisTarih ? formatDate(kullanici.bitisTarih.toDate()) : "-",
        kullanici.mapsKonum ? kullanici.mapsKonum.latitude : "-",
        kullanici.mapsKonum ? kullanici.mapsKonum.longitude : "-",
      ];
      csvRows.push(rowData.join(";"));
    });

    const csvContent = "data:text/csv;charset=utf-8," + csvRows.join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "kullanicilar.csv");

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="w-full 2xl:px-96 mt-3">
      <div className="flex items-center mb-4">
        <select
          value={durum}
          onChange={(e) => setDurum(e.target.value)}
          className="mr-2 px-3 py-2 border rounded"
        >
          <option value="Durum Seç">Durum Seç</option>
          <option value="Aktif">Aktif</option>
          <option value="Pasif">Pasif</option>
        </select>
        <input
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          className="mr-2 px-3 py-2 border rounded"
        />
        <input
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          className="mr-2 px-3 py-2 border rounded"
        />
        <button
          onClick={downloadCSV}
          className="bg-orange-400 py-2 w-40 rounded-xl text-white font-bold"
        >
          CSV İndir
        </button>
      </div>
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-sm font-semibold text-gray-500 uppercase tracking-wider">
              Firma Kodu
            </th>
            <th className="px-6 py-3 text-left text-sm font-semibold text-gray-500 uppercase tracking-wider">
              Firma Adı
            </th>
            <th className="px-6 py-3 text-left text-sm font-semibold text-gray-500 uppercase tracking-wider">
              Tel
            </th>
            <th className="px-6 py-3 text-left text-sm font-semibold text-gray-500 uppercase tracking-wider">
              Unvan
            </th>
            <th className="px-6 py-3 text-left text-sm font-semibold text-gray-500 uppercase tracking-wider">
              Üyelik Başlangıç
            </th>
            <th className="px-6 py-3 text-left text-sm font-semibold text-gray-500 uppercase tracking-wider">
              Üyelik Bitiş
            </th>
            <th className="px-6 py-3 text-left text-sm font-semibold text-gray-500 uppercase tracking-wider">
              Durum
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-zinc-200">
          {filteredKullanicilar.map((kullanici, index) => (
            <tr
              key={kullanici.id}
              className={index % 2 === 0 ? "bg-gray-100" : "bg-white"}
            >
              <td className="px-6 py-4 text-md font-bold whitespace-nowrap">
                {kullanici.firmaKod}
              </td>
              <td className="px-6 py-4 text-md font-bold whitespace-nowrap">
                {kullanici.firmaAd ? kullanici.firmaAd : "-"}
              </td>
              <td className="px-6 py-4 text-md font-bold whitespace-nowrap">
                {kullanici.tel ? kullanici.tel : "-"}
              </td>
              <td className="px-6 py-4 text-md font-bold whitespace-nowrap">
                {kullanici.unvan ? kullanici.unvan : "-"}
              </td>
              <td className="px-6 py-4 text-md font-semibold whitespace-nowrap">
                {formatDate(kullanici.stamp.toDate())}
              </td>
              <td className="px-6 py-4 text-md font-semibold whitespace-nowrap">
                {kullanici.bitisTarih
                  ? formatDate(kullanici.bitisTarih.toDate())
                  : "-"}
              </td>
              <td className="px-6 py-4 text-md font-bold whitespace-nowrap">
                {durumKontrol(kullanici)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TumKullanici;
