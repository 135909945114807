import React from "react";
import flogo from "../../assets/img/flogo.png";

const Footer = () => {
  return (
    <div className="py-5 bg-white shadow-inner items-center px-5 gap-3 md:gap-0 flex md:flex-row flex-col justify-between">
      <p>
        &copy; 2024 <span className="font-bold">Minimenü</span> tüm hakları
        saklıdır.
      </p>
      <img src={flogo} className="w-32" />
    </div>
  );
};

export default Footer;
