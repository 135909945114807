import React, { useRef, useEffect, useState } from "react";
import logo from "../../../assets/img/logo.png";
import nullPNG from "../../../assets/img/null.png";
import QRCode from "qrcode.react";
import ColorThief from 'colorthief';

const Yazdir = ({ firmaK, cozulmus }) => {
  const [qrCodeDataURL, setQrCodeDataURL] = useState("");
  const qrRef = useRef(null);

  // Color extraction function
  const extractColors = async (imageElement) => {
    const colorThief = new ColorThief();
    try {
      const palette = await colorThief.getPalette(imageElement, 3);
      return {
        primary: `rgb(${palette[0].join(',')})`,
        secondary: `rgb(${palette[1].join(',')})`,
        accent: `rgb(${palette[2].join(',')})`,
      };
    } catch (error) {
      console.error('Color extraction failed:', error);
      return null;
    }
  };

  const loadImage = (src) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = src;
    });
  };
  

  useEffect(() => {
    const qrCanvas = qrRef.current.querySelector("canvas");
    const qrDataURL = qrCanvas.toDataURL("image/png");
    setQrCodeDataURL(qrDataURL);
  }, []);

  const handleYazdir = async () => {
    const kategoriler = cozulmus.urunGrup;
    const yemekler = cozulmus.menu?.sort((a, b) => a.siraNo - b.siraNo);

    const [logoImg, resimImg] = await Promise.all([
      loadImage(logo),
      loadImage(cozulmus.resimURL || nullPNG)
    ]);

    const colors = await extractColors(resimImg);

    const printWindow = window.open(
      `minimenu.com.tr/${firmaK}`,
      "print window",
      "width=800,height=600"
    );
    
    printWindow.document.write(`
          <html>
          <head>
              <meta charset="UTF-8">
              <meta name="viewport" content="width=device-width, initial-scale=1.0">
              <title>${cozulmus.firmaAd} Menü</title>
              <style>
                  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');
                  :root {
                    --primary-color:  ${colors?.primary || '#333333'};
                    --secondary-color: ${colors?.secondary || '#666666'};
                    --accent-color: ${colors?.accent || '#999999'};
                  }

                  body {
                      font-family: 'Roboto', sans-serif;
                      line-height: 1;
                      color: #333;
                      max-width: 210mm; /* A4 width */
                      margin: 0 auto;
                      padding: 20px;
                      padding-top: 0;
                      margin-top: 0;
                  }

                  .menu-top{
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      margin-bottom: 10px;
                  }

                  .menu-top img {
                      width: 100px;
                      height: auto;
                  }

                  h1 {
                      text-align: center;
                      font-size: 36px;
                      margin-bottom: 4px;
                      color: var(--primary-color);
                  }

                  .menu-title {
                      font-size: 32px;
                      margin-bottom: 6px;
                      font-weight: normal;
                      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3); /* Soft shadow for title */
                  }

                  h2 {
                      font-size: 28px;
                      border-bottom: 2px solid var(--secondary-color);
                      padding-bottom: 0px;
                      margin-top: 14px;
                      margin-bottom: 0px;
                      page-break-after: avoid;
                      color: var(--secondary-color);
                  }

                  .menu-content {
                      display: flex;
                      flex-wrap: wrap; /* Allow items to wrap into the next row */
                      justify-content: space-between; /* Space between columns */
                      position: relative;
                  }

                  .divider {
                      position: absolute;
                      top: 16px;
                      left: 50%; /* Center the divider */
                      height: calc(100% - 24px); /* Full height minus 32px */
                      width: 0;
                      border-left: 2px solid var(--accent-color); /* Vertical line */
                      z-index: 1; /* Ensure the line is on top of the content */
                  }

                  .menu-item {
                      flex: 0 0 46%;
                      border-bottom: 1px solid #e0e0e0; /* Light border around each item */
                      margin-bottom: 0;
                      margin-top: 0;
                      padding: 0;
                      page-break-inside: avoid;
                  }

                  .menu-item h3 {
                      font-size: 22px;
                      margin-bottom: 0px;
                  }

                  .menu-item p {
                      margin: 0;
                      margin-top: 0; /* Ensure no top margin */
                      margin-bottom: 0; /* Ensure no bottom margin */
                      font-size: 12px;
                      page-break-inside: avoid;
                      max-width: 66.67%; /* 2/3 of the page width */
                      word-wrap: break-word;
                  }

                  .price {
                      float: right;
                      font-weight: bold;
                  }
                      
                  .footer-section {
                    margin-top: 20px;
                    text-align: center;
                    page-break-inside: avoid;
                  }

                  .qr-container {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-bottom: 10px;
                  }

                  .qr-title {
                    font-size: 18px;
                    margin-bottom: 5px;
                    font-weight: bold;
                  }

                  .qr-code {
                    width: 100px;
                    height: 100px;
                  }

                  .firma-address {
                    font-size: 14px;
                    color: #666;
                    margin-top: 5px;
                  }

                  @media print {
                      body {
                          max-width: 100%;
                          padding: 0;
                      }

                      @page {
                          size: A4;
                          margin: 2cm;
                          margin-top: 0.8cm;
                          margin-bottom: 1.2cm;
                      }

                      h1 {
                          font-size: 28pt;
                      }

                      h2 {
                          font-size: 20pt;
                      }

                      .menu-item h3 {
                          font-size: 14pt;
                      }

                      .price {
                          font-size: 14pt;
                          margin: 0;
                          margin-top: 0; /* Ensure no top margin */
                          margin-bottom: 0; /* Ensure no bottom margin */
                      }

                      .menu-item p {
                          font-size: 10pt;
                          margin: 0;
                          margin-top: 0; /* Ensure no top margin */
                          margin-bottom: 0; /* Ensure no bottom margin */
                      }
                  }

                  @media print and (max-height: 210mm) {
                      @page {
                          size: A5;
                          margin: 1cm;
                      }

                      h1 {
                          font-size: 20pt;
                      }

                      h2 {
                          font-size: 16pt;
                      }

                      .menu-item h3 {
                          font-size: 12pt;
                      }

                      .menu-item p, .price {
                          font-size: 10pt;
                          margin: 0;
                          margin-top: 0; /* Ensure no top margin */
                          margin-bottom: 0; /* Ensure no bottom margin */
                      }
                  }

                  @media screen and (max-width: 210mm) {
                      body {
                          padding: 10px;
                      }

                      h1 {
                          font-size: 24px;
                      }

                      h2 {
                          font-size: 20px;
                      }

                      .menu-item h3 {
                          font-size: 16px;
                      }

                      .menu-item p {
                          font-size: 12px;
                          margin: 0;
                      }
                  }
              </style>
          </head>
          <body>
              <div class="menu-top">
                <img src="${resimImg.src}" alt="logo">
                <div style= "text-align: center;">
                  <h1>${cozulmus.firmaAd}</h1>
                  <h1 class="menu-title">MENÜ</h1>
                </div>
                <img src="${qrCodeDataURL}" alt="qrkod">
              </div>

                ${kategoriler
                  .map((kategori) => `
                  <h2>${kategori}</h2>
                  <div class="menu-content">
                  <div class="divider"></div>
                      ${yemekler
                        .filter((yemek) => yemek.kategori === kategori)
                        .map((yemek) => `
                          <div class="menu-item">
                            <h3>${yemek.ad}<span class="price">${yemek.fiyat} ₺</span></h3>
                            <p>${yemek.aciklama ? yemek.aciklama : ""}</p>
                            <p class="portion">${yemek.miktar ? yemek.miktar : ""}</p>
                          </div>
                        `).join("")}
                        </div>
                  `).join("")}

              <div class="footer-section">
              <div class="qr-container">
                <div class="qr-title">Dijital Menu</div>
                <img class="qr-code" src="${qrCodeDataURL}" alt="QR Code" />
              </div>
              <div class="firma-address">
                ${'www.minimenu.com.tr/' + firmaK}
              </div>
            </div>
          </body>
          </html>`);
    printWindow.document.close();
    printWindow.onload = () => {
      printWindow.print();
    };
  };

  const handleCSV = () => {
    const kategoriler = cozulmus.urunGrup;
    const yemekler = cozulmus.menu;

    let csvContent = "\uFEFF";
    csvContent += "Kategori;Ad;Miktar;Fiyat;Açıklama\n";

    kategoriler.forEach((kategori) => {
      yemekler
        .filter((yemek) => yemek.kategori === kategori)
        .forEach((yemek) => {
          csvContent += `${kategori};${yemek.ad};${yemek.miktar};${
            yemek.fiyat
          };${yemek.aciklama ? yemek.aciklama : ""}\n`;
        });
    });

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "menu.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    URL.revokeObjectURL(url);
  };

  return (
    <div className="fixed font-manrope bg-neutral-800 bg-opacity-70 overflow-y-auto inset-0">
      <div className="flex items-center justify-center min-h-screen">
        <div className="w-80 md:w-2/6 py-4 bg-white flex flex-col items-center justify-center rounded-xl">
          <p className="text-2xl font-bold">Menü Yazdır</p>
          <div className="flex flex-col items-center">
            <div ref={qrRef} style={{ display: "none" }}>
              <QRCode
                value={`minimenu.com.tr/${firmaK}`}
                size={150}
                bgColor="#ffffff"
                fgColor="#000000"
              />
            </div>
            <button
              onClick={handleYazdir}
              className="py-2 w-80 bg-green-500 text-white text-lg font-bold mt-5 rounded-xl border hover:scale-105"
            >
              Çıktı Al
            </button>
            <button
              onClick={handleCSV}
              className="py-2 w-80 bg-orange-500 text-white text-lg font-bold mt-5 rounded-xl border hover:scale-105"
            >
              CSV İndir
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Yazdir;
