import React, { useEffect, useState } from "react";
import { generateRandomSifre } from "../../utils/kulOlustur";
import { addDoc, collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import "react-datepicker/dist/react-datepicker.css";
import toast from "react-hot-toast";

const KullaniciEkle = () => {
  const [tekrarCalis, setTekrarCalis] = useState(false);
  const [kulAd, setKulAd] = useState("");
  const [sifre, setSifre] = useState("");
  const [firmaKod, setFirmaKod] = useState("");
  const [uyeGunSayisi, setUyeGunSayisi] = useState(365);

  useEffect(() => {
    const getBayi = async () => {
      try {
        const ref = collection(db, "menu");
        const donenDoc = await getDocs(ref);
        const bayiSayi = "aa00" + (donenDoc.size + 1);
        setFirmaKod(bayiSayi);
        setSifre(generateRandomSifre(4));
        setKulAd("ax" + generateRandomSifre(4));
      } catch (error) {
        console.log(error);
      }
    };

    getBayi();
  }, [tekrarCalis]);

  const tekrarGetir = () => {
    setTekrarCalis(!tekrarCalis);
  };

  const formatDate = (date) => {
    const options = { day: "numeric", month: "long", year: "numeric" };
    return date.toLocaleDateString("tr-TR", options);
  };

  const handleOlustur = async (e) => {
    e.preventDefault();
    try {
      const ref = collection(db, "menu");

      const bitisDate = new Date();
      bitisDate.setDate(bitisDate.getDate() + uyeGunSayisi);

      await addDoc(ref, {
        kullanici: kulAd,
        sifre,
        firmaAd: "-",
        tel: "-",
        firmaKod,
        durum: true,
        createdAt: new Date(),
        stamp: new Date(),
        bitisTarih: bitisDate,
      });

      tekrarGetir();
      toast.success("Kullanıcı Oluşturuldu.");
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="fixed z-50 font-manrope inset-0 bg-neutral-800 bg-opacity-70 overflow-y-auto">
      <div className="min-h-screen flex items-center justify-center">
        <div className="w-80 md:w-2/6 py-4 bg-white rounded-xl px-2">
          <p className="text-2xl font-bold">Alt Kullanıcı Oluştur</p>
          <form onSubmit={handleOlustur} className="flex gap-2 flex-col">
            <div className="flex flex-col gap-1">
              <label className="text-lg" htmlFor="kulAd">
                Kullanıcı Adı
              </label>
              <input
                type="text"
                value={kulAd}
                onChange={(e) => setKulAd(e.target.value)}
                id="kulAd"
                className="py-2 bg-neutral-200 text-lg rounded-xl px-2"
                placeholder="Kullanıcı Adı"
              />
            </div>
            <div className="flex flex-col gap-1">
              <label className="text-lg" htmlFor="firmaKod">
                Firma Kodu
              </label>
              <input
                type="text"
                value={firmaKod}
                onChange={(e) => setFirmaKod(e.target.value)}
                id="firmaKod"
                className="py-2 bg-neutral-200 text-lg rounded-xl px-2"
                placeholder="Firma Kodu"
              />
            </div>
            <div className="flex flex-col gap-1">
              <label className="text-lg" htmlFor="sifre">
                Şifre
              </label>
              <input
                type="text"
                id="sifre"
                value={sifre}
                onChange={(e) => setSifre(e.target.value)}
                className="py-2 bg-neutral-200 text-lg rounded-xl px-2"
                placeholder="Şifre"
              />
            </div>
            <div className="flex flex-col gap-1">
              <label className="text-lg" htmlFor="uyeGunSayisi">
                Üyelik Gün Sayısı
              </label>
              <input
                type="number"
                id="uyeGunSayisi"
                value={uyeGunSayisi}
                onChange={(e) => setUyeGunSayisi(parseInt(e.target.value))}
                className="py-2 bg-neutral-200 text-lg rounded-xl px-2"
                placeholder="Üyelik Gün Sayısı"
              />
            </div>
            <button
              type="submit"
              className="py-2 bg-neutral-700 text-white active:scale-105 hover:bg-neutral-500 text-lg font-bold rounded-3xl mt-1"
            >
              Oluştur
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default KullaniciEkle;
