import React, { useEffect, useState } from "react";
import logo from "../../assets/img/logo.png";
import { deleteCookie, getCookieValue } from "../../services/getCookie";
import { sifreCoz } from "../../services/getPass";
import Joyride from "react-joyride";
import { useNavigate } from "react-router-dom";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebase";
import UrunGrup from "../../modals/bayi/yonetim/UrunGrup";
import UrunEkle from "../../modals/bayi/yonetim/UrunEkle";
import FirmaIsim from "../../modals/bayi/yonetim/FirmaIsim";
import FirmaLogo from "../../modals/bayi/yonetim/FirmaLogo";
import FirmaTel from "../../modals/bayi/yonetim/FirmaTelefon";
import FirmaAdres from "../../modals/bayi/yonetim/FirmaAdres";
import FirmaHakkimda from "../../modals/bayi/yonetim/FirmaHakkimda";
import KulBilgi from "../../modals/bayi/yonetim/KulBilgi";
import QRModal from "../../modals/bayi/yonetim/FirmaQR";
import Yazdir from "../../modals/bayi/yonetim/Yazdir";

const Panel = () => {
  const token = getCookieValue("token");
  const navigate = useNavigate();
  const [runTour, setRunTour] = useState(false);
  const [cozulmus, setCozulmus] = useState({});
  const [sifreModal, setSifreModal] = useState(false);
  const [otuzGun, setOtuzGun] = useState(false);
  const [urunGrupModal, setUrunGrupModal] = useState(false);
  const [urunEkleModal, setUrunEkleModal] = useState(false);
  const [firmaIsimModal, setFirmaIsimModal] = useState(false);
  const [firmaLogoModal, setFirmaLogoModal] = useState(false);
  const [firmaQR, setFirmaQR] = useState(false);
  const [firmaTelefonModal, setFirmaTelefonModal] = useState(false);
  const [firmaAdresModal, setFirmaAdresModal] = useState(false);
  const [yazdirModal, setYazdirModal] = useState(false);
  const [modalAcik, setModalAcik] = useState(false);
  const [hakkimdaModal, setHakkimdaModal] = useState(false);
  const [yukleniyor, setYukleniyor] = useState(true);
  const [uyelikBitti, setUyelikBitti] = useState(false);
  const [kalanGun, setKalanGun] = useState("");
  const [firmaKodu, setFirmaKodu] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const itemsPerPage = 5;

  const tourSteps = [
    {
      target: ".kulkod",
      content: "Kullanıcı Kodunuz",
      disableBeacon: true,
    },
    {
      target: ".urungrup",
      content: `Ürün grubu eklemenizi sağlayan düğme. Örnek "Ana Yemek, İçecek"`,
      disableBeacon: true,
    },
    {
      target: ".urunekle",
      content: "Ürün grubu seçip, ekleyiniz",
      disableBeacon: true,
    },
    {
      target: ".urunler",
      content: "Ürünleri düzenlemenizi sağlayan düğme",
      disableBeacon: true,
    },
    {
      target: ".kulbilgi",
      content: "Şifrenizi düzenlemenizi sağlayan düğme",
      disableBeacon: true,
    },
    {
      target: ".firmaisim",
      content: `Firma isminizi değiştirmenizi sağlayan düğme`,
      disableBeacon: true,
    },
    {
      target: ".firmalogo",
      content: `Firma logonuzu eklemenizi sağlayan düğme`,
      disableBeacon: true,
    },
    {
      target: ".firmatel",
      content: `Firma telefon numaranızı eklemenizi sağlayan düğme`,
      disableBeacon: true,
    },
    {
      target: ".firmadres",
      content: `Firma adresinizi eklemenizi sağlayan düğme`,
      disableBeacon: true,
    },
    {
      target: ".duyuru",
      content: `Duyuru yapabilirsiniz.`,
      disableBeacon: true,
    },
    {
      target: ".kampanya",
      content: `Kampanya oluşturabilirsiniz.`,
      disableBeacon: true,
    },
    {
      target: ".karekod",
      content: `Sayfanıza ait karekodu görüntülemenizi ve indirmenizi sağlayan düğme`,
      disableBeacon: true,
    },
    {
      target: ".yazdir",
      content: `Buradan fiyat listenizi baskıya uygun biçimde indirebilirsiniz`,
      disableBeacon: true,
    },
    {
      target: ".hakkinda",
      content: `Buradan firma hakkında kısmını güncelleyebilirsiniz.`,
      disableBeacon: true,
    },
  ];

  useEffect(() => {
    if (token) {
      tokenKontrol(token);
    } else {
      navigate("/");
    }
  }, [token]);

  useEffect(() => {
    const getMenu = async () => {
      try {
        const mRef = collection(db, "menu");
        const qM = query(mRef, where("firmaKod", "==", firmaKodu));
        const getM = await getDocs(qM);
        if (!getM.empty) {
          const getData = getM.docs[0].data();
          setCozulmus(getData);
          setYukleniyor(false);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getMenu();
  }, [firmaKodu]);

  useEffect(() => {
    const bilgi = cozulmus;
    if (bilgi.bitisTarih) {
      const bitisTarih = bilgi.bitisTarih.toDate();
      const simdikiTarih = new Date();
      const farkMs = bitisTarih - simdikiTarih;
      const gunFarki = Math.ceil(farkMs / (1000 * 60 * 60 * 24));
      setKalanGun(gunFarki);
      if (gunFarki <= 30) {
        setOtuzGun(true);
        if (gunFarki < 0) {
          setOtuzGun(false);
          setUyelikBitti(true);
        }
      }
    }
  }, [cozulmus]);

  const tokenKontrol = (gelenToken) => {
    const cozulmusVeri = sifreCoz(gelenToken);
    if (cozulmusVeri.firmaKod) {
      setFirmaKodu(cozulmusVeri.firmaKod);
    } else {
      navigate("/");
    }
  };

  const handleCik = () => {
    deleteCookie("token");
    navigate("/");
  };

  const siralanmisYemek =
    cozulmus.menu?.sort((a, b) => a.siraNo - b.siraNo) || [];
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = siralanmisYemek.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleModalSifirla = () => {
    setUrunEkleModal(false);
    setUrunGrupModal(false);
    setFirmaQR(false);
    setFirmaIsimModal(false);
    setFirmaLogoModal(false);
    setYazdirModal(false);
    setFirmaTelefonModal(false);
    setHakkimdaModal(false);
    setFirmaAdresModal(false);
    setSifreModal(false);
    setModalAcik(false);
  };

  return (
    <div className="min-h-screen flex flex-col">
      <Joyride
        steps={tourSteps}
        run={runTour}
        continuous={true}
        showProgress={true}
        showSkipButton={true}
        disableOverlayClose={true}
        spotlightClicks={true}
        locale={{
          next: "İleri",
          back: "Geri",
          skip: "Atla",
          last: "Son",
        }}
      />
      {yukleniyor ? null : (
        <>
          <div className="flex pt-2 items-center justify-evenly">
            <img src={logo} className="w-28 p-2 bg-black rounded-xl" />
            <p className="font-semibold text-lg kulkod">{firmaKodu}</p>
          </div>
          <div className="flex flex-col mt-2 items-center">
            <img src={cozulmus.resimURL} className="w-40 rounded-xl" />
            <p className="text-2xl mt-2 text-center text-black font-bold">
              {cozulmus.firmaAd}
            </p>
            <p className="text-gray-800 font-semibold">{cozulmus.yer}</p>
            {otuzGun && (
              <p className="py-3 bg-orange-500 shadow-xl text-center rounded-xl text-lg border text-white mt-2 mb-2 px-2">
                Üyeliğinizin bitmesine{" "}
                <span className="font-bold">{kalanGun}</span> gün kaldı.
              </p>
            )}
            {uyelikBitti && (
              <div>
                <p className="py-3 bg-red-600 w-80 shadow-xl text-center rounded-xl text-lg border text-white mt-2 mb-2 px-2">
                  Üyeliğinizin süresi <span className="font-bold">doldu.</span>{" "}
                  <br />
                  Lütfen +90 552 379 80 39 arayın.
                </p>
                <p className="py-3 bg-orange-500 w-80 shadow-xl text-center rounded-xl text-lg border text-white mt-2 mb-2 px-2">
                  Fonksiyonlar pasif hale{" "}
                  <span className="font-bold">getirildi.</span>{" "}
                </p>
              </div>
            )}
            <>
              {currentItems && (
                <div className="w-80 md:w-2/6 flex flex-col items-center mt-2 gap-2 justify-center">
                  {currentItems.map((yemek, index) => (
                    <div
                      key={index}
                      className="w-full flex md:flex-row flex-col items-center justify-between py-2 px-2 bg-neutral-200 shadow-2xl border-b-2 rounded-xl border-neutral-200 border-r-2 "
                    >
                      <p className="font-bold w-64 bg-white py-1 rounded-full text-center text-lg">
                        {yemek.ad}
                      </p>
                      <div className="flex items-center mt-2 md:mt-0 gap-2">
                        <p className="w-28 text-center font-semibold text-black text-md md:text-lg rounded-full">
                          {yemek.miktar}
                        </p>
                        <p className="bg-red-600 text-white w-28 text-center font-semibold text-md md:text-lg rounded-full">
                          {yemek.fiyat} ₺
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              <div className="flex w-80 md:w-2/6 flex-wrap gap-2 py-1 justify-center mt-4">
                {Array.from(
                  { length: Math.ceil(siralanmisYemek.length / itemsPerPage) },
                  (_, index) => (
                    <button
                      key={index}
                      onClick={() => paginate(index + 1)}
                      className={`px-2 py-0.5 rounded ${
                        currentPage === index + 1
                          ? "bg-gray-800 text-white"
                          : "bg-gray-200"
                      }`}
                    >
                      {index + 1}
                    </button>
                  )
                )}
              </div>
            </>
            <div className="w-[350px] md:w-1/2 mt-5 mb-2 md:mb-0 border-b-4 py-2 px-1 md:px-5 border-r-2 border-neutral-400 shadow rounded-xl bg-neutral-200">
              <p className="text-center pt-2 text-2xl font-semibold">
                Yönetim Paneli
              </p>
              <div className="flex items-center justify-center">
                <div className="w-auto grid grid-cols-2 px-2 md:px-0 gap-5 py-3 md:py-5">
                  <div className="flex cursor-pointer items-center">
                    <button
                      onClick={() => setRunTour(true)}
                      className="py-2 hover:bg-orange-400 text-white active:scale-105 font-semibold rounded-l-full w-36 md:w-60 bg-orange-500"
                    >
                      UYG. TURU
                    </button>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width={40}
                      className="py-2 rounded-r-full bg-white"
                      height={40}
                      color={"grey"}
                      fill={"none"}
                    >
                      <path
                        d="M2 10H7"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M2 17H7"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M2 3H19"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M19.6 18.6L22 21M20.8 14.4C20.8 11.4176 18.3824 9 15.4 9C12.4176 9 10 11.4176 10 14.4C10 17.3824 12.4176 19.8 15.4 19.8C18.3824 19.8 20.8 17.3824 20.8 14.4Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="flex cursor-pointer items-center">
                    <button
                      onClick={handleCik}
                      className="py-2 hover:bg-red-500 active:scale-105 font-semibold rounded-l-full w-36 md:w-60 bg-red-600 text-white"
                    >
                      ÇIKIŞ YAP
                    </button>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width={40}
                      className="py-2 rounded-r-full  bg-white"
                      height={40}
                      color={"gray"}
                      fill={"none"}
                    >
                      <path
                        d="M7.02331 5.5C4.59826 7.11238 3 9.86954 3 13C3 17.9706 7.02944 22 12 22C16.9706 22 21 17.9706 21 13C21 9.86954 19.4017 7.11238 16.9767 5.5"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12 2V10"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <button
                    onClick={() => {
                      setUrunGrupModal(true);
                      setModalAcik(true);
                    }}
                    disabled={uyelikBitti}
                    className="flex cursor-pointer items-center"
                  >
                    <button
                      disabled={uyelikBitti}
                      className="py-2 urungrup hover:bg-red-40 text-white active:scale-105 font-semibold rounded-l-full disabled:scale-100 w-36 md:w-60 disabled:bg-red-300 bg-red-600"
                    >
                      ÜRÜN GRUBU
                    </button>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width={40}
                      className="py-2 rounded-r-full  bg-white"
                      height={40}
                      color={"gray"}
                      fill={"none"}
                    >
                      <path
                        d="M12 8V16M16 12L8 12"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                      />
                    </svg>
                  </button>
                  <button
                    onClick={() => {
                      setUrunEkleModal(true);
                      setModalAcik(true);
                    }}
                    disabled={uyelikBitti}
                    className="flex cursor-pointer items-center"
                  >
                    <button
                      disabled={uyelikBitti}
                      className="py-2 urunekl hover:bg-red-400 active:scale-105 disabled:scale-100 disabled:bg-red-300 font-semibold rounded-l-full w-36 md:w-60 bg-red-600 text-white"
                    >
                      ÜRÜN EKLE
                    </button>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width={40}
                      className="py-2 rounded-r-full  bg-white"
                      height={40}
                      color={"gray"}
                      fill={"none"}
                    >
                      <path
                        d="M12 8V16M16 12L8 12"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                      />
                    </svg>
                  </button>
                  <button
                    onClick={() => {
                      navigate("/bayi/duzenle");
                    }}
                    disabled={uyelikBitti}
                    className="flex cursor-pointer urunler items-center"
                  >
                    <button
                      disabled={uyelikBitti}
                      className="py-2 urungrup disabled:bg-red-300 disabled:scale-100  hover:bg-red-40 text-white active:scale-105 font-semibold rounded-l-full w-36 md:w-60 bg-red-600"
                    >
                      ÜRÜNLER
                    </button>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width={40}
                      className="py-2 rounded-r-full bg-white"
                      height={40}
                      color={"grey"}
                      fill={"none"}
                    >
                      <path
                        d="M14.0737 3.88545C14.8189 3.07808 15.1915 2.6744 15.5874 2.43893C16.5427 1.87076 17.7191 1.85309 18.6904 2.39232C19.0929 2.6158 19.4769 3.00812 20.245 3.79276C21.0131 4.5774 21.3972 4.96972 21.6159 5.38093C22.1438 6.37312 22.1265 7.57479 21.5703 8.5507C21.3398 8.95516 20.9446 9.33578 20.1543 10.097L10.7506 19.1543C9.25288 20.5969 8.504 21.3182 7.56806 21.6837C6.63212 22.0493 5.6032 22.0224 3.54536 21.9686L3.26538 21.9613C2.63891 21.9449 2.32567 21.9367 2.14359 21.73C1.9615 21.5234 1.98636 21.2043 2.03608 20.5662L2.06308 20.2197C2.20301 18.4235 2.27297 17.5255 2.62371 16.7182C2.97444 15.9109 3.57944 15.2555 4.78943 13.9445L14.0737 3.88545Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M13 4L20 11"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M14 22L22 22"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                  <button
                    onClick={() => {
                      setSifreModal(true);
                      setModalAcik(true);
                    }}
                    disabled={uyelikBitti}
                    className="flex cursor-pointer kulbilgi items-center"
                  >
                    <button
                      disabled={uyelikBitti}
                      className="py-2 disabled:bg-red-300 disabled:scale-100 urunekl hover:bg-red-400 active:scale-105 font-semibold rounded-l-full w-36 md:w-60 bg-red-600 text-white"
                    >
                      ŞİFRE
                    </button>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width={40}
                      className="py-2 rounded-r-full bg-white"
                      height={40}
                      color={"grey"}
                      fill={"none"}
                    >
                      <path
                        d="M14.0737 3.88545C14.8189 3.07808 15.1915 2.6744 15.5874 2.43893C16.5427 1.87076 17.7191 1.85309 18.6904 2.39232C19.0929 2.6158 19.4769 3.00812 20.245 3.79276C21.0131 4.5774 21.3972 4.96972 21.6159 5.38093C22.1438 6.37312 22.1265 7.57479 21.5703 8.5507C21.3398 8.95516 20.9446 9.33578 20.1543 10.097L10.7506 19.1543C9.25288 20.5969 8.504 21.3182 7.56806 21.6837C6.63212 22.0493 5.6032 22.0224 3.54536 21.9686L3.26538 21.9613C2.63891 21.9449 2.32567 21.9367 2.14359 21.73C1.9615 21.5234 1.98636 21.2043 2.03608 20.5662L2.06308 20.2197C2.20301 18.4235 2.27297 17.5255 2.62371 16.7182C2.97444 15.9109 3.57944 15.2555 4.78943 13.9445L14.0737 3.88545Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M13 4L20 11"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M14 22L22 22"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                  <button
                    onClick={() => {
                      setFirmaIsimModal(true);
                      setModalAcik(true);
                    }}
                    disabled={uyelikBitti}
                    className="flex cursor-pointer items-center"
                  >
                    <button
                      disabled={uyelikBitti}
                      className="py-1 md:py-2 text-lg disabled:bg-red-300 disabled:scale-100 firmaisim hover:bg-red-40 active:scale-105 font-semibold rounded-l-full w-36 md:w-60 bg-red-600 text-white"
                    >
                      FİRMA İSİM
                    </button>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width={40}
                      className="py-2 rounded-r-full  bg-white"
                      height={40}
                      color={"gray"}
                      fill={"none"}
                    >
                      <path
                        d="M16.9767 19.5C19.4017 17.8876 21 15.1305 21 12C21 7.02944 16.9706 3 12 3C11.3126 3 10.6432 3.07706 10 3.22302M16.9767 19.5V16M16.9767 19.5H20.5M7 4.51555C4.58803 6.13007 3 8.87958 3 12C3 16.9706 7.02944 21 12 21C12.6874 21 13.3568 20.9229 14 20.777M7 4.51555V8M7 4.51555H3.5"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                  <button
                    onClick={() => {
                      setFirmaLogoModal(true);
                      setModalAcik(true);
                    }}
                    disabled={uyelikBitti}
                    className="flex cursor-pointer items-center"
                  >
                    <button
                      disabled={uyelikBitti}
                      className="py-2 disabled:bg-red-300 disabled:scale-100 firmalogo hover:bg-red-40 active:scale-105 font-semibold rounded-l-full w-36 md:w-60 bg-red-600 text-white"
                    >
                      FİRMA LOGO
                    </button>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width={40}
                      className="py-2 rounded-r-full bg-white"
                      height={40}
                      color={"grey"}
                      fill={"none"}
                    >
                      <path
                        d="M11.508 2.99023C7.02518 2.99023 4.78379 2.99023 3.39116 4.38232C1.99854 5.77441 1.99854 8.01494 1.99854 12.496C1.99854 16.977 1.99854 19.2176 3.39116 20.6097C4.78379 22.0018 7.02518 22.0018 11.508 22.0018C15.9907 22.0018 18.2321 22.0018 19.6248 20.6097C21.0174 19.2176 21.0174 16.977 21.0174 12.496V11.9957"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                      <path
                        d="M4.99854 20.9897C9.20852 16.2384 13.9397 9.93721 20.9985 14.6631"
                        stroke="currentColor"
                        strokeWidth="1.5"
                      />
                      <path
                        d="M17.9958 1.99829V10.0064M22.0014 5.97728L13.9902 5.99217"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                  <button
                    onClick={() => {
                      setFirmaTelefonModal(true);
                      setModalAcik(true);
                    }}
                    disabled={uyelikBitti}
                    className="flex cursor-pointer items-center"
                  >
                    <button
                      disabled={uyelikBitti}
                      className="py-2 disabled:bg-red-300 disabled:scale-100 firmatel hover:bg-red-40 active:scale-105 font-semibold rounded-l-full w-36 md:w-60 bg-red-600 text-white"
                    >
                      TELEFON
                    </button>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width={40}
                      className="py-2 rounded-r-full bg-white"
                      height={40}
                      color={"gray"}
                      fill={"none"}
                    >
                      <path
                        d="M3.77762 11.9424C2.8296 10.2893 2.37185 8.93948 2.09584 7.57121C1.68762 5.54758 2.62181 3.57081 4.16938 2.30947C4.82345 1.77638 5.57323 1.95852 5.96 2.6524L6.83318 4.21891C7.52529 5.46057 7.87134 6.08139 7.8027 6.73959C7.73407 7.39779 7.26737 7.93386 6.33397 9.00601L3.77762 11.9424ZM3.77762 11.9424C5.69651 15.2883 8.70784 18.3013 12.0576 20.2224M12.0576 20.2224C13.7107 21.1704 15.0605 21.6282 16.4288 21.9042C18.4524 22.3124 20.4292 21.3782 21.6905 19.8306C22.2236 19.1766 22.0415 18.4268 21.3476 18.04L19.7811 17.1668C18.5394 16.4747 17.9186 16.1287 17.2604 16.1973C16.6022 16.2659 16.0661 16.7326 14.994 17.666L12.0576 20.2224Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                  <button
                    onClick={() => {
                      setFirmaAdresModal(true);
                      setModalAcik(true);
                    }}
                    disabled={uyelikBitti}
                    className="flex cursor-pointer items-center"
                  >
                    <button
                      disabled={uyelikBitti}
                      className="py-2 disabled:bg-red-300 disabled:scale-100 firmadres hover:bg-red-40 active:scale-105 font-semibold rounded-l-full w-36 md:w-60 bg-red-600 text-white"
                    >
                      ADRES
                    </button>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      className="py-2 rounded-r-full bg-white"
                      width={40}
                      height={40}
                      color={"grey"}
                      fill={"none"}
                    >
                      <path
                        d="M14.5 10C14.0697 8.55426 12.5855 7.5 11 7.5C9.067 7.5 7.5 9.067 7.5 11C7.5 12.7632 8.80385 14.2574 10.5 14.5"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                      <path
                        d="M19.9504 10C19.4697 5.53446 15.5596 2 11 2C6.12944 2 2 6.03298 2 10.9258C2 15.9137 6.2039 19.3616 10.073 21.7567C10.3555 21.9162 10.675 22 11 22C11.325 22 11.6445 21.9162 11.927 21.7567C12.1816 21.6009 12.4376 21.4403 12.6937 21.2748"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                      <path
                        d="M17.5 12C19.9353 12 22 14.0165 22 16.4629C22 18.9482 19.9017 20.6924 17.9635 21.8783C17.8223 21.9581 17.6625 22 17.5 22C17.3375 22 17.1777 21.9581 17.0365 21.8783C15.1019 20.6808 13 18.9568 13 16.4629C13 14.0165 15.0647 12 17.5 12Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                      />
                      <path
                        d="M17.5 16.5H17.509"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                  <button
                    onClick={() => {
                      navigate("/bayi/duyurular");
                    }}
                    disabled={uyelikBitti}
                    className="flex cursor-pointer items-center"
                  >
                    <button
                      disabled={uyelikBitti}
                      className="py-2 disabled:bg-red-300 duyuru disabled:scale-100 hover:bg-red-40 active:scale-105 font-semibold rounded-l-full w-36 md:w-60 bg-red-600 text-white"
                    >
                      DUYURU
                    </button>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width={40}
                      height={40}
                      className="py-2 rounded-r-full bg-white"
                      color={"grey"}
                      fill={"none"}
                    >
                      <path
                        d="M14.9263 4.41103L8.27352 7.60452C7.76151 7.85029 7.21443 7.91187 6.65675 7.78693C6.29177 7.70517 6.10926 7.66429 5.9623 7.64751C4.13743 7.43912 3 8.88342 3 10.5443V11.4557C3 13.1166 4.13743 14.5609 5.9623 14.3525C6.10926 14.3357 6.29178 14.2948 6.65675 14.2131C7.21443 14.0881 7.76151 14.1497 8.27352 14.3955L14.9263 17.589C16.4534 18.3221 17.217 18.6886 18.0684 18.4029C18.9197 18.1172 19.2119 17.5041 19.7964 16.278C21.4012 12.9112 21.4012 9.08885 19.7964 5.72196C19.2119 4.49586 18.9197 3.88281 18.0684 3.5971C17.217 3.3114 16.4534 3.67794 14.9263 4.41103Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M13 17V17.5C13 18.7841 13 19.4261 12.776 19.7886C12.4773 20.2719 11.9312 20.545 11.3653 20.4939C10.9409 20.4557 10.4273 20.0704 9.4 19.3L8.2 18.4C7.22253 17.6669 7 17.2218 7 16V14.5"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M7.5 14V8"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                  <button
                    onClick={() => {
                      navigate("/bayi/kampanyalar");
                    }}
                    disabled={uyelikBitti}
                    className="flex cursor-pointer items-center"
                  >
                    <button
                      disabled={uyelikBitti}
                      className="py-2 disabled:bg-red-300 disabled:scale-100 kampanya hover:bg-red-40 active:scale-105 font-semibold rounded-l-full w-36 md:w-60 bg-red-600 text-white"
                    >
                      KAMPANYA
                    </button>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width={40}
                      height={40}
                      className="py-2 rounded-r-full bg-white"
                      color={"grey"}
                      fill={"none"}
                    >
                      <path
                        d="M17.5 5C18.3284 5 19 5.67157 19 6.5C19 7.32843 18.3284 8 17.5 8C16.6716 8 16 7.32843 16 6.5C16 5.67157 16.6716 5 17.5 5Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M2.77423 11.1439C1.77108 12.2643 1.7495 13.9546 2.67016 15.1437C4.49711 17.5033 6.49674 19.5029 8.85633 21.3298C10.0454 22.2505 11.7357 22.2289 12.8561 21.2258C15.8979 18.5022 18.6835 15.6559 21.3719 12.5279C21.6377 12.2187 21.8039 11.8397 21.8412 11.4336C22.0062 9.63798 22.3452 4.46467 20.9403 3.05974C19.5353 1.65481 14.362 1.99377 12.5664 2.15876C12.1603 2.19608 11.7813 2.36233 11.472 2.62811C8.34412 5.31646 5.49781 8.10211 2.77423 11.1439Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                      />
                      <path
                        d="M13.7884 12.3665C13.8097 11.9655 13.9222 11.232 13.3125 10.6744M13.3125 10.6744C13.1238 10.5019 12.866 10.3462 12.5149 10.2225C11.2583 9.77958 9.71484 11.2619 10.8067 12.6188C11.3936 13.3482 11.8461 13.5725 11.8035 14.4008C11.7735 14.9834 11.2012 15.5922 10.4469 15.824C9.7916 16.0255 9.06876 15.7588 8.61156 15.2479C8.05332 14.6241 8.1097 14.0361 8.10492 13.7798M13.3125 10.6744L14.0006 9.98633M8.66131 15.3256L8.00781 15.9791"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                  <button
                    onClick={() => {
                      setHakkimdaModal(true);
                      setModalAcik(true);
                    }}
                    disabled={uyelikBitti}
                    className="flex cursor-pointer col-span-2  items-center"
                  >
                    <button
                      disabled={uyelikBitti}
                      className="py-2 disabled:bg-red-300 hakkinda duyuru disabled:scale-100 hover:bg-red-40 active:scale-105 font-semibold rounded-l-full w-full bg-red-600 text-white"
                    >
                      FİRMA HAKKINDA
                    </button>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width={40}
                      className="py-2 rounded-r-full  bg-white"
                      height={40}
                      color={"gray"}
                      fill={"none"}
                    >
                      <path
                        d="M16.9767 19.5C19.4017 17.8876 21 15.1305 21 12C21 7.02944 16.9706 3 12 3C11.3126 3 10.6432 3.07706 10 3.22302M16.9767 19.5V16M16.9767 19.5H20.5M7 4.51555C4.58803 6.13007 3 8.87958 3 12C3 16.9706 7.02944 21 12 21C12.6874 21 13.3568 20.9229 14 20.777M7 4.51555V8M7 4.51555H3.5"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                  <button
                    onClick={() => {
                      navigate("/bayi/resimler");
                    }}
                    disabled={uyelikBitti}
                    className="flex col-span-2 cursor-pointer items-center"
                  >
                    <button
                      disabled={uyelikBitti}
                      className="py-2 disabled:bg-red-300 w-full disabled:scale-100 kampanya hover:bg-red-40 active:scale-105 font-semibold rounded-l-full bg-red-600 text-white"
                    >
                      İŞLETME RESİMLERİ
                    </button>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      className="py-2 rounded-r-full bg-white lucide lucide-image"
                      height="40"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      color="grey"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <rect width="18" height="18" x="3" y="3" rx="2" ry="2" />
                      <circle cx="9" cy="9" r="2" />
                      <path d="m21 15-3.086-3.086a2 2 0 0 0-2.828 0L6 21" />
                    </svg>
                  </button>
                  <button
                    onClick={() => {
                      setFirmaQR(true);
                      setModalAcik(true);
                    }}
                    disabled={uyelikBitti}
                    className="flex cursor-pointer items-center"
                  >
                    <button
                      disabled={uyelikBitti}
                      className="disabled:bg-blue-300 py-2 karekod hover:bg-blue-40 active:scale-105 font-semibold rounded-l-full w-36 md:w-60 bg-blue-600 text-white"
                    >
                      KAREKOD
                    </button>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width={40}
                      className="py-2 rounded-r-full bg-white"
                      height={40}
                      color={"grey"}
                      fill={"none"}
                    >
                      <path
                        d="M3 6C3 4.58579 3 3.87868 3.43934 3.43934C3.87868 3 4.58579 3 6 3C7.41421 3 8.12132 3 8.56066 3.43934C9 3.87868 9 4.58579 9 6C9 7.41421 9 8.12132 8.56066 8.56066C8.12132 9 7.41421 9 6 9C4.58579 9 3.87868 9 3.43934 8.56066C3 8.12132 3 7.41421 3 6Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                      />
                      <path
                        d="M3 18C3 16.5858 3 15.8787 3.43934 15.4393C3.87868 15 4.58579 15 6 15C7.41421 15 8.12132 15 8.56066 15.4393C9 15.8787 9 16.5858 9 18C9 19.4142 9 20.1213 8.56066 20.5607C8.12132 21 7.41421 21 6 21C4.58579 21 3.87868 21 3.43934 20.5607C3 20.1213 3 19.4142 3 18Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                      />
                      <path
                        d="M3 12L9 12"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12 3V8"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M15 6C15 4.58579 15 3.87868 15.4393 3.43934C15.8787 3 16.5858 3 18 3C19.4142 3 20.1213 3 20.5607 3.43934C21 3.87868 21 4.58579 21 6C21 7.41421 21 8.12132 20.5607 8.56066C20.1213 9 19.4142 9 18 9C16.5858 9 15.8787 9 15.4393 8.56066C15 8.12132 15 7.41421 15 6Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                      />
                      <path
                        d="M21 12H15C13.5858 12 12.8787 12 12.4393 12.4393C12 12.8787 12 13.5858 12 15M12 17.7692V20.5385M15 15V16.5C15 17.9464 15.7837 18 17 18C17.5523 18 18 18.4477 18 19M16 21H15M18 15C19.4142 15 20.1213 15 20.5607 15.44C21 15.8799 21 16.5881 21 18.0043C21 19.4206 21 20.1287 20.5607 20.5687C20.24 20.8898 19.7767 20.9766 19 21"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                    </svg>
                  </button>
                  <button
                    onClick={() => {
                      setModalAcik(true);
                      setYazdirModal(true);
                    }}
                    disabled={uyelikBitti}
                    className="flex cursor-pointer items-center"
                  >
                    <button
                      disabled={uyelikBitti}
                      className="py-2 disabled:bg-blue-300 disabled:scale-100 yazdir hover:bg-blue-40 active:scale-105 font-semibold rounded-l-full w-36 md:w-60 bg-blue-600 text-white"
                    >
                      YAZDIR
                    </button>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width={40}
                      className="py-2 bg-white rounded-r-full"
                      height={40}
                      color={"gray"}
                      fill={"none"}
                    >
                      <path
                        d="M7.35396 18C5.23084 18 4.16928 18 3.41349 17.5468C2.91953 17.2506 2.52158 16.8271 2.26475 16.3242C1.87179 15.5547 1.97742 14.5373 2.18868 12.5025C2.36503 10.8039 2.45321 9.95455 2.88684 9.33081C3.17153 8.92129 3.55659 8.58564 4.00797 8.35353C4.69548 8 5.58164 8 7.35396 8H16.646C18.4184 8 19.3045 8 19.992 8.35353C20.4434 8.58564 20.8285 8.92129 21.1132 9.33081C21.5468 9.95455 21.635 10.8039 21.8113 12.5025C22.0226 14.5373 22.1282 15.5547 21.7352 16.3242C21.4784 16.8271 21.0805 17.2506 20.5865 17.5468C19.8307 18 18.7692 18 16.646 18"
                        stroke="currentColor"
                        strokeWidth="1.5"
                      />
                      <path
                        d="M17 8V6C17 4.11438 17 3.17157 16.4142 2.58579C15.8284 2 14.8856 2 13 2H11C9.11438 2 8.17157 2 7.58579 2.58579C7 3.17157 7 4.11438 7 6V8"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M13.9887 16L10.0113 16C9.32602 16 8.98337 16 8.69183 16.1089C8.30311 16.254 7.97026 16.536 7.7462 16.9099C7.57815 17.1904 7.49505 17.5511 7.32884 18.2724C7.06913 19.3995 6.93928 19.963 7.02759 20.4149C7.14535 21.0174 7.51237 21.5274 8.02252 21.7974C8.40513 22 8.94052 22 10.0113 22L13.9887 22C15.0595 22 15.5949 22 15.9775 21.7974C16.4876 21.5274 16.8547 21.0174 16.9724 20.4149C17.0607 19.963 16.9309 19.3995 16.6712 18.2724C16.505 17.5511 16.4218 17.1904 16.2538 16.9099C16.0297 16.536 15.6969 16.254 15.3082 16.1089C15.0166 16 14.674 16 13.9887 16Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M18 12H18.009"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <p className="text-md w-full py-2 bg-neutral-100 text-center mt-2">
              www.minimenu.com.tr <br />
              Üyelik bitişine kalan gün <br />{" "}
              <span className="font-bold">{kalanGun}</span>
              <br />
              0552 379 80 39
            </p>
          </div>
        </>
      )}
      {urunGrupModal && <UrunGrup firmaK={firmaKodu} />}
      {urunEkleModal && <UrunEkle firmaK={firmaKodu} />}
      {firmaIsimModal && <FirmaIsim firmaK={firmaKodu} />}
      {firmaLogoModal && <FirmaLogo firmaK={firmaKodu} />}
      {firmaTelefonModal && <FirmaTel firmaK={firmaKodu} />}
      {hakkimdaModal && <FirmaHakkimda firmaK={firmaKodu} />}
      {firmaAdresModal && <FirmaAdres firmaK={firmaKodu} />}
      {firmaQR && <QRModal firmaK={firmaKodu} firmaAd={cozulmus.firmaAd} />}
      {sifreModal && <KulBilgi firmaK={firmaKodu} />}
      {yazdirModal && <Yazdir firmaK={firmaKodu} cozulmus={cozulmus} />}
      {modalAcik && (
        <button
          onClick={() => {
            handleModalSifirla();
          }}
          className="fixed font-bold text-lg rounded-full active:scale-105 hover:bg-red-500 bottom-5 md:bottom-20 left-1/2 transform -translate-x-1/2 py-3 bg-red-600 text-white px-20"
        >
          Kapat
        </button>
      )}
    </div>
  );
};

export default Panel;
