import React, { useState } from "react";
import { db } from "../../../firebase";
import {
  collection,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import toast from "react-hot-toast";

const FirmaIsim = ({ firmaK }) => {
  const [yeniFirmaTel, setYeniFirmaTel] = useState("+90");
  const [wpTel, setWpTel] = useState("+90");

  const handleDegistir = async (e) => {
    e.preventDefault();
    try {
      const menuRef = collection(db, "menu");
      const mQ = query(menuRef, where("firmaKod", "==", firmaK));
      const getDoc = await getDocs(mQ);

      if (yeniFirmaTel === "+90" || wpTel === "+90") {
        alert("Lütfen Wp ve Sipariş telefon giriniz.");
      } else {
        getDoc.forEach(async (donenDoc) => {
          const docRef = donenDoc.ref;
          await updateDoc(docRef, {
            tel: yeniFirmaTel,
            wpTel: wpTel,
          });
        });

        setYeniFirmaTel("+90");
        setWpTel("+90");
        toast.success("WP No ve Sipariş Tel değiştirildi.");
      }
    } catch (error) {
      toast.error("Sistemde hata oluştu.");
      console.log(error);
    }
  };

  return (
    <div className="fixed font-manrope bg-neutral-800 bg-opacity-70 overflow-y-auto inset-0">
      <div className="flex  items-center justify-center min-h-screen">
        <div className="w-80 md:w-2/6 py-6 bg-white flex flex-col items-center justify-center rounded-2xl shadow-xl transform transition-all duration-300">
          <p className="text-2xl text-center font-bold mb-4">
            Telefon Numaralarını Değiştir
          </p>
          <form
            className="flex items-center flex-col justify-center gap-2"
            onSubmit={handleDegistir}
          >
            <div className="relative w-80 flex items-center justify-center">
              <span className="absolute px-2 md:px-0 inset-y-0 left-4 flex items-center text-neutral-600">
                +90
              </span>
              <input
                type="phone"
                maxLength={10}
                value={yeniFirmaTel.substring(3)}
                onChange={(e) => setYeniFirmaTel("+90" + e.target.value)}
                placeholder="WhatsApp No"
                className="w-72 md:w-full bg-neutral-300 placeholder-neutral-600 rounded-full py-2 text-center focus:outline-none focus:ring-2 focus:ring-green-400"
                required
              />
            </div>
            <input
              maxLength={12}
              type="phone"
              value={wpTel.substring(3)}
              onChange={(e) => setWpTel("+90" + e.target.value)}
              placeholder="Sipariş No"
              className="w-72 md:w-full bg-neutral-300 placeholder-neutral-600 rounded-full py-2 text-center focus:outline-none focus:ring-2 focus:ring-green-400"
              required
            />
            <button
              type="submit"
              className="text-xl bg-green-400 text-white rounded-full py-2 w-72 md:w-80 font-bold transform transition-transform hover:scale-105"
            >
              Değiştir
            </button>
          </form>
          <p className="mx-auto text-black bg-orange-300 w-72 md:w-80 text-center border rounded-xl py-2 mt-2 bg-opacity-45">
            Numaraları başında sıfır <span className="font-bold">olmadan</span>{" "}
            (5XXXXXXXXX) giriniz.
          </p>
        </div>
      </div>
    </div>
  );
};

export default FirmaIsim;
