import React, { useState } from "react";
import { db } from "../../../firebase";
import {
  collection,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import toast from "react-hot-toast";

const FirmaIsim = ({ firmaK }) => {
  const [yeniFirmaYer, setYeniFirmaYer] = useState("");
  const [il, setIl] = useState("");
  const [ilce, setIlce] = useState("");
  const [mahalle, setMahalle] = useState("");
  const [sokak, setSokak] = useState("");
  const [lat, setLat] = useState(null);
  const [long, setLong] = useState(null);

  const handleDegistir = async (e) => {
    e.preventDefault();
    try {
      const menuRef = collection(db, "menu");
      const mQ = query(menuRef, where("firmaKod", "==", firmaK));
      const getDoc = await getDocs(mQ);

      if (yeniFirmaYer === "") {
        toast.error("Lütfen Firma Yeri Giriniz");
      } else {
        getDoc.forEach(async (donenDoc) => {
          const docRef = donenDoc.ref;
          await updateDoc(docRef, {
            yer: yeniFirmaYer,
          });
        });

        toast.success("Firma yeri değiştirildi");
      }
    } catch (error) {
      toast.error("Sistemde hata oluştu.");
      console.log(error);
    }
  };

  const handleTamDegistir = async (e) => {
    e.preventDefault();
    try {
      const menuRef = collection(db, "menu");
      const mQ = query(menuRef, where("firmaKod", "==", firmaK));
      const getDoc = await getDocs(mQ);

      if (il === "") {
        toast.error("Lütfen İl, İlçe, Mahalle ve Sokak giriniz.");
      } else {
        getDoc.forEach(async (donenDoc) => {
          const docRef = donenDoc.ref;
          await updateDoc(docRef, {
            tamAdres: {
              il,
              ilce,
              mahalle,
              sokak,
            },
          });
        });

        toast.success("Firma adresi değiştirildi.");
      }
    } catch (error) {
      alert("Sistemde hata oluştu.");
      console.log(error);
    }
  };

  const handleMaps = async () => {
    try {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(async (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          setLat(latitude);
          setLong(longitude);

          const menuRef = collection(db, "menu");
          const mQ = query(menuRef, where("firmaKod", "==", firmaK));
          const getDoc = await getDocs(mQ);

          getDoc.forEach(async (donenDoc) => {
            const docR = donenDoc.ref;
            await updateDoc(docR, {
              mapsKonum: {
                latitude,
                longitude,
              },
            });
          });
          toast.success("Konum güncellendi.");
        });
      } else {
        toast.error("Tarayıcınız anlık konum desteklemiyor.");
      }
    } catch (error) {
      toast.error("Konum güncellenirken bir hata oluştu.");
      console.error("Konum alınırken bir hata oluştu:", error);
    }
  };

  return (
    <div className="fixed font-manrope bg-neutral-800 bg-opacity-70 overflow-y-auto inset-0">
      <div className="flex items-center justify-center min-h-screen">
        <div className="w-80 md:w-2/6 py-4 bg-white flex flex-col items-center justify-center rounded-xl">
          <p className="text-2xl font-bold">Firma Adres Değiştir</p>
          <div className="flex flex-col items-center">
            <form className="flex items-center flex-col justify-center">
              <input
                type="text"
                value={yeniFirmaYer}
                onChange={(e) => setYeniFirmaYer(e.target.value)}
                placeholder="Yer (Örn. İstanbul/Beykoz"
                className="w-60 bg-neutral-300 placeholder-neutral-600 mt-3 rounded-full py-2 text-center"
                required
              />
              <button
                type="submit"
                onClick={handleDegistir}
                className="text-xl bg-green-500 text-white rounded-full mt-3 font-bold py-2 w-60"
              >
                Değiştir
              </button>
            </form>
            <p className="text-2xl font-bold mt-2">Firma Tam Adres Değiştir</p>
            <form className="flex w-full border-b-8 pb-2 items-center flex-col justify-center">
              <div className="flex items-center gap-2 justify-center">
                <input
                  type="text"
                  value={il}
                  onChange={(e) => setIl(e.target.value)}
                  placeholder="İl"
                  className="w-32 bg-slate-300 placeholder-neutral-600 mt-3 rounded-full py-2 text-center"
                  required
                />
                <input
                  type="text"
                  value={ilce}
                  onChange={(e) => setIlce(e.target.value)}
                  placeholder="İlçe"
                  className="w-32 bg-slate-300 placeholder-neutral-600 mt-3 rounded-full py-2 text-center"
                  required
                />
              </div>
              <div className="flex items-center gap-2">
                <input
                  type="text"
                  value={mahalle}
                  onChange={(e) => setMahalle(e.target.value)}
                  placeholder="Mahalle"
                  className="w-32 bg-slate-300 placeholder-neutral-600 mt-3 rounded-full py-2 text-center"
                  required
                />
                <input
                  type="text"
                  value={sokak}
                  onChange={(e) => setSokak(e.target.value)}
                  placeholder="Sokak"
                  className="w-32 bg-slate-300 placeholder-neutral-600 mt-3 rounded-full py-2 text-center"
                  required
                />
              </div>
              <button
                type="submit"
                onClick={handleTamDegistir}
                className="text-xl bg-green-500 text-white rounded-full mt-3 font-bold py-2 w-60"
              >
                Değiştir
              </button>
            </form>
            <button
              onClick={handleMaps}
              className="text-xl bg-orange-500 text-white rounded-full mt-3 font-bold py-2 w-60"
            >
              Maps Konum Ekle
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirmaIsim;
