import React, { useEffect, useState } from "react";
import { db } from "../../firebase";
import { Timestamp, addDoc, collection, getDocs, query, where } from "firebase/firestore";
import { generateRandomSifre } from "../../utils/kulOlustur";
import { getCookieValue } from "../../services/getCookie";
import { sifreCoz, veriSifrele } from "../../services/getPass";
import kvkk from "../../assets/kvkk.docx";
import toast from "react-hot-toast";

const KayitModal = ({ onClose }) => {
  const [formData, setFormData] = useState({
    adSoyad: "",
    firmaKod: "",
    kul: "",
    isletmeAdi: "",
    kullaniciAd: "",
    sifre: "",
    email: "",
    telefon: "+90",
    kvkkKabul: false,
  });

  const [sifreliadsifre, setSifreliadsifre] = useState();

  const [mailGonderildi, setMailGonderildi] = useState(false);
  const tkn2 = getCookieValue("token2");
  const mRef = collection(db, "menu");

  useEffect(() => {
    if (tkn2) {
      setMailGonderildi(true);
      setSifreliadsifre(sifreCoz(tkn2));
    }
  }, [tkn2]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (name === "telefon") {
      let formattedValue = value.replace(/[^\d+]/g, "");
      if (!formattedValue.startsWith("+90")) {
        formattedValue = "+90" + formattedValue;
      }
      setFormData({
        ...formData,
        [name]: formattedValue,
      });
    } else if (name === "firmaKod") {
      // Firma kodu için büyük harf, boşluk ve Türkçe karakterleri engelle
      let formattedValue = value
        .replace(/[\sÇçĞğİıÖöŞşÜü]/g, "") // Türkçe karakterleri ve boşlukları siler
        .toLowerCase(); // Küçük harfe çevirir

      setFormData({
        ...formData,
        [name]: formattedValue,
      });
    } else {
      setFormData({
        ...formData,
        [name]: type === "checkbox" ? checked : value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let unique = true;
    
    //Database ile girilen alanların özel olup olmadığını konntrol et
    //Özel değilse hata mesajı ver
    if (!formData.kvkkKabul) {
      toast.error("KVKK metnini okuyup onaylamanız gerekmektedir.");
      unique = false;
    }

    const emailQuery = query(mRef, where('email', '==', formData.email));
    const phoneQuery = query(mRef, where('tel', '==', formData.telefon));
    const firmaKodQuery = query(mRef, where('firmaKod', '==', formData.firmaKod));

    const emailSnapshot = await getDocs(emailQuery);
    const phoneSnapshot = await getDocs(phoneQuery);
    const firmaKodSnapshot = await getDocs(firmaKodQuery);

    if (!emailSnapshot.empty) {
      toast.error("Bu mail adresi zaten kullanılmakta.");
      unique = false;
    } 
    else if (!phoneSnapshot.empty) {
      toast.error("Bu telefon numarası zaten kullanılmakta.");
      unique = false;
    }
    else if (!firmaKodSnapshot.empty) {
      toast.error("Bu işletme linki zaten kullanılmakta.");
      unique = false;
    }

    if (unique) {
      try {
        const kulgen = generateRandomSifre(4); //TODO: daha güvenlikli yap.
        const sifregen = generateRandomSifre(4);
        const now = new Date();
        const bitisTarih = new Date(now);
        bitisTarih.setDate(bitisTarih.getDate() + 30);
        const data = {
          firmaAd: formData.isletmeAdi,
          durum: true,
          firmaKod: formData.firmaKod,
          kullanici: "aa" + kulgen,
          email: formData.email,
          tel: formData.telefon,
          sifre: sifregen,
          stamp: now,
          createdAt: now,
          bitisTarih: Timestamp.fromDate(bitisTarih),
        };
        await addDoc(mRef, data);
        const sifreliadsifre = {
          ads: formData.adSoyad,
          ad: "aa" + kulgen,
          sfr: sifregen,
        };
        setSifreliadsifre(sifreliadsifre);
        const sifreli = veriSifrele(sifreliadsifre);
        document.cookie = `token2=${sifreli}; path=/`;

        setMailGonderildi(true);//for testing

        
      } catch (error) {
        toast.error("Bir sorun var lütfen daha sonra tekrar deneyiniz.");
      }
    }
  };

  return (
    <>
      <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
        <div className="bg-white p-6 rounded-lg shadow-lg w-96 relative">
          <button
            onClick={onClose}
            className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
          >
            X
          </button>
          <h2 className="text-2xl font-semibold mb-4">1 Ay Ücretsiz Dene</h2>
          {mailGonderildi ? (
            <div>
              <p>
                <span className="font-bold">
                  {formData.adSoyad || sifreliadsifre.ads},
                </span>{" "}
                1 aylık deneme hesabınız oluşturulmuştur.
              </p>
              <p className="mt-4">
                Kullanıcı Adınız:{" "}
                <span className="font-bold">{sifreliadsifre.ad}</span>
              </p>
              <p className="mt-4">
                Şifre: <span className="font-bold">{sifreliadsifre.sfr}</span>
              </p>
            </div>
          ) : (
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label
                  className="block text-sm font-medium text-gray-700"
                  htmlFor="adSoyad"
                >
                  Ad Soyad
                </label>
                <input
                  type="text"
                  id="adSoyad"
                  name="adSoyad"
                  value={formData.adSoyad}
                  onChange={handleChange}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  required
                />
              </div>
              <div className="mb-4">
                <label
                  className="block text-sm font-medium text-gray-700"
                  htmlFor="isletmeAdi"
                >
                  İşletme Adı
                </label>
                <input
                  type="text"
                  id="isletmeAdi"
                  name="isletmeAdi"
                  value={formData.isletmeAdi}
                  onChange={handleChange}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  required
                />
              </div>
              <div className="mb-4">
                <label
                  className="block text-sm font-medium text-gray-700"
                  htmlFor="firmaKod"
                >
                  İşletme Linki
                </label>
                <input
                  type="text"
                  id="firmaKod"
                  name="firmaKod"
                  value={formData.firmaKod}
                  onChange={handleChange}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  required
                />
                <p className="text-sm ml-1 mt-0.5 text-gray-600 font-medium">
                  minimenu.com.tr/
                  <span className="text-red-600 font-semibold">
                    {formData.firmaKod}
                  </span>
                </p>
              </div>
              <div className="mb-4">
                <label
                  className="block text-sm font-medium text-gray-700"
                  htmlFor="email"
                >
                  Mail
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  required
                />
              </div>
              <div className="mb-4">
                <label
                  className="block text-sm font-medium text-gray-700"
                  htmlFor="telefon"
                >
                  Telefon No
                </label>
                <input
                  type="tel"
                  id="telefon"
                  name="telefon"
                  max={10}
                  value={formData.telefon}
                  onChange={handleChange}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  required
                />
              </div>
              <div className="mb-4 flex items-center">
                <input
                  type="checkbox"
                  id="kvkkKabul"
                  name="kvkkKabul"
                  checked={formData.kvkkKabul}
                  onChange={handleChange}
                  className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                  required
                />
                <label
                  htmlFor="kvkkKabul"
                  className="ml-2 block text-sm text-gray-900"
                >
                  <a href={kvkk} download className="text-indigo-600">
                    KVKK metnini
                  </a>{" "}
                  okudum, onaylıyorum.
                </label>
              </div>
              <button
                type="submit"
                className="w-full py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Hesap Oluştur
              </button>
            </form>
          )}
        </div>
      </div>
    </>
  );
};

export default KayitModal;
