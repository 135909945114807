import React, { useEffect, useState } from "react";
import logo from "../../assets/img/logo.png";
import KullaniciEkle from "../../modals/yonetici/KullaniciEkle";
import KKontrol from "../../components/yonetici/KulKontrol";
import { collection, getDocs } from "firebase/firestore";
import CSVModal from "../../modals/yonetici/CSVModal";
import { db } from "../../firebase";
import TumKullanici from "../../components/yonetici/TumKullanici";

const Panel = () => {
  const [tumKul, setTumKul] = useState(false);
  const [aktifKul, setAktifKul] = useState(0);
  const [pasifKul, setPasifKul] = useState(0);
  const [modalAcik, setModalAcik] = useState(false);
  const [altKulOlusturModal, setAltKulOlusturModal] = useState(false);
  const [csvModal, setCSVModal] = useState(false);
  const [kulKontrol, setKulKontrol] = useState(false);
  const [kulSayi, setKulSayi] = useState(0);

  useEffect(() => {
    const getMenu = async () => {
      try {
        const mRef = collection(db, "menu");
        const getM = await getDocs(mRef);
        setKulSayi(getM.size);
        let aktifCount = 0;
        let pasifCount = 0;
        getM.forEach((donen) => {
          const data = donen.data();
          const endDate = data.bitisTarih.toDate();
          const now = new Date();
          if (endDate > now) {
            aktifCount++;
          } else {
            pasifCount++;
          }
        });
        setAktifKul(aktifCount);
        setPasifKul(pasifCount);
      } catch (error) {
        console.log("Sistemde hata oluştu: ", error);
      }
    };

    getMenu();
  }, []);

  const handleSifirla = () => {
    setAltKulOlusturModal(false);
    setCSVModal(false);
    setModalAcik(false);
  };

  return (
    <div className="min-h-screen font-manrope">
      <div className="py-2 rounded-b-full shadow-2xl shadow-gray-600 bg-black">
        <img src={logo} className="mx-auto w-40" />
      </div>
      <div className="2xl:mt-20 mt-5">
        <h1 className="text-center text-4xl font-bold">Yönetim Paneli</h1>
        <div className="flex 2xl:flex-row flex-col gap-3 2xl:gap-0 items-center justify-between px-5 2xl:px-96 mt-5">
          <div className="py-4 px-5 flex items-center justify-between bg-neutral-400 2xl:w-80 w-full rounded-3xl border">
            <div>
              <p className="text-xl  text-white font-bold">
                Alt Kullanıcı Sayısı
              </p>
              <p className="text-3xl text-white font-bold">{kulSayi}</p>
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width={80}
              height={80}
              color={"white"}
              fill={"none"}
            >
              <path
                d="M2.9668 10.4961V15.4979C2.9668 18.3273 2.9668 19.742 3.84548 20.621C4.72416 21.5001 6.13837 21.5001 8.9668 21.5001H14.9668C17.7952 21.5001 19.2094 21.5001 20.0881 20.621C20.9668 19.742 20.9668 18.3273 20.9668 15.4979V10.4961"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
              <path
                d="M14.9668 16.9932C14.2827 17.6004 13.1936 17.9932 11.9668 17.9932C10.74 17.9932 9.65089 17.6004 8.9668 16.9932"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
              <path
                d="M10.1038 8.41848C9.82182 9.43688 8.79628 11.1936 6.84777 11.4482C5.12733 11.673 3.82246 10.922 3.48916 10.608C3.12168 10.3534 2.28416 9.53872 2.07906 9.02952C1.87395 8.52032 2.11324 7.41706 2.28416 6.96726L2.96743 4.98888C3.13423 4.49196 3.5247 3.31666 3.92501 2.91913C4.32533 2.5216 5.13581 2.5043 5.4694 2.5043H12.4749C14.2781 2.52978 18.2209 2.48822 19.0003 2.50431C19.7797 2.52039 20.2481 3.17373 20.3848 3.45379C21.5477 6.27061 22 7.88382 22 8.57124C21.8482 9.30456 21.22 10.6873 19.0003 11.2955C16.6933 11.9275 15.3854 10.6981 14.9751 10.2261M9.15522 10.2261C9.47997 10.625 10.4987 11.4279 11.9754 11.4482C13.4522 11.4686 14.7273 10.4383 15.1802 9.92062C15.3084 9.76786 15.5853 9.31467 15.8725 8.41848"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className="py-4 px-5 flex items-center justify-between bg-green-400 2xl:w-80 w-full rounded-3xl border">
            <div>
              <p className="text-xl text-white font-bold">
                Aktif Alt Kullanıcı
              </p>
              <p className="text-3xl text-white font-bold">{aktifKul}</p>
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width={80}
              height={80}
              color={"white"}
              fill={"none"}
            >
              <path
                d="M7.32612 18.092C6.99877 18.3667 6.71531 18.5029 6.42014 18.5C5.73998 18.4931 5.17341 17.7974 4.04028 16.4061L2.52531 14.5458C1.90142 13.7798 1.82509 12.6392 2.34013 11.7785C2.92845 10.7954 4.05674 10.52 4.93607 11.0133M10.9222 8.5C11.7133 7.66585 12.5575 6.8322 13.4783 5.98679C13.9225 5.57893 14.4762 5.43447 15 5.52681"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
              <path
                d="M14.1057 16.311C16.3656 13.6916 18.5472 11.9146 21.2876 9.39818C22.1078 8.64496 22.2345 7.3247 21.591 6.3893C20.9072 5.39517 19.6077 5.19647 18.7282 5.98679C16.218 8.24236 14.2651 10.4141 12.4126 12.7354C12.3154 12.8572 12.2668 12.9181 12.2163 12.9524C12.0884 13.0393 11.9285 13.0403 11.7998 12.9548C11.749 12.9211 11.7003 12.8613 11.6029 12.7419L10.6158 11.5311C9.71508 10.4263 8.09956 10.5486 7.34754 11.7785C6.82128 12.6392 6.89927 13.7798 7.53675 14.5458L9.0847 16.4061C10.2425 17.7974 10.8214 18.4931 11.5164 18.5C12.2114 18.5068 12.8428 17.7748 14.1057 16.311Z"
                stroke="currentColor"
                strokeWidth="1.5"
              />
            </svg>
          </div>{" "}
          <div className="py-4 px-5 flex items-center justify-between bg-red-400 2xl:w-80 w-full rounded-3xl border">
            <div>
              <p className="text-xl font-bold text-white">
                Pasif Alt Kullanıcı
              </p>
              <p className="text-3xl font-extrabold text-white">{pasifKul}</p>
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width={80}
              height={80}
              color={"white"}
              fill={"none"}
            >
              <path
                d="M5.75 5L19.75 19"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M22.75 12C22.75 6.47715 18.2728 2 12.75 2C7.22715 2 2.75 6.47715 2.75 12C2.75 17.5228 7.22715 22 12.75 22C18.2728 22 22.75 17.5228 22.75 12Z"
                stroke="currentColor"
                strokeWidth="1.5"
              />
            </svg>
          </div>
        </div>
        <div className="flex sm:flex-row flex-col items-center justify-between w-full gap-3 px-5 2xl:px-96 mt-3">
          <div
            onClick={() => {
              setModalAcik(true);
              setAltKulOlusturModal(true);
            }}
            className="flex-1 cursor-pointer w-full py-4 hover:bg-zinc-900 active:scale-105 bg-zinc-800 rounded-xl flex px-5 items-center justify-between"
          >
            <p className="text-2xl font-extrabold text-white">
              Alt Kullanıcı Oluştur
            </p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width={80}
              height={80}
              color={"white"}
              fill={"none"}
            >
              <path
                d="M2.9668 10.002V15.002C2.9668 17.8304 2.9668 19.2446 3.84548 20.1233C4.72416 21.002 6.13837 21.002 8.9668 21.002H12.4668M20.9668 10.002V11.502"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
              <path
                d="M14.9668 18.502H21.9668M18.4668 22.002V15.002"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
              <path
                d="M6.9668 17.002H10.9668"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
              <path
                d="M10.1038 7.92324C9.82182 8.94288 8.79628 10.7018 6.84777 10.9567C5.12733 11.1817 3.82246 10.4299 3.48916 10.1155C3.12168 9.86056 2.28416 9.04485 2.07906 8.53503C1.87395 8.02521 2.11324 6.9206 2.28416 6.47026L2.96743 4.48946C3.13423 3.99194 3.5247 2.81521 3.92501 2.4172C4.32533 2.01918 5.13581 2.00187 5.4694 2.00187H12.4749C14.2781 2.02737 18.2209 1.98577 19.0003 2.00187C19.7797 2.01797 20.2481 2.67211 20.3848 2.95251C21.5477 5.77276 22 7.38793 22 8.07619C21.8482 8.8104 21.22 10.1948 19.0003 10.8037C16.6933 11.4366 15.3854 10.2057 14.9751 9.7331M9.15522 9.7331C9.47997 10.1325 10.4987 10.9363 11.9754 10.9567C13.4522 10.9771 14.7273 9.94553 15.1802 9.42721C15.3084 9.27426 15.5853 8.82053 15.8725 7.92324"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div
            onClick={() => {
              setKulKontrol(!kulKontrol);
            }}
            className="flex-1 py-4 cursor-pointer w-full hover:bg-zinc-900 active:scale-105 bg-zinc-800 rounded-xl flex px-5 items-center justify-between"
          >
            <p className="text-2xl font-extrabold text-white">
              Alt Kullanıcı Kontrolleri
            </p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width={80}
              height={80}
              color={"white"}
              fill={"none"}
            >
              <path
                d="M15.5 12C15.5 13.933 13.933 15.5 12 15.5C10.067 15.5 8.5 13.933 8.5 12C8.5 10.067 10.067 8.5 12 8.5C13.933 8.5 15.5 10.067 15.5 12Z"
                stroke="currentColor"
                strokeWidth="1.5"
              />
              <path
                d="M21.011 14.0965C21.5329 13.9558 21.7939 13.8854 21.8969 13.7508C22 13.6163 22 13.3998 22 12.9669V11.0332C22 10.6003 22 10.3838 21.8969 10.2493C21.7938 10.1147 21.5329 10.0443 21.011 9.90358C19.0606 9.37759 17.8399 7.33851 18.3433 5.40087C18.4817 4.86799 18.5509 4.60156 18.4848 4.44529C18.4187 4.28902 18.2291 4.18134 17.8497 3.96596L16.125 2.98673C15.7528 2.77539 15.5667 2.66972 15.3997 2.69222C15.2326 2.71472 15.0442 2.90273 14.6672 3.27873C13.208 4.73448 10.7936 4.73442 9.33434 3.27864C8.95743 2.90263 8.76898 2.71463 8.60193 2.69212C8.43489 2.66962 8.24877 2.77529 7.87653 2.98663L6.15184 3.96587C5.77253 4.18123 5.58287 4.28891 5.51678 4.44515C5.45068 4.6014 5.51987 4.86787 5.65825 5.4008C6.16137 7.3385 4.93972 9.37763 2.98902 9.9036C2.46712 10.0443 2.20617 10.1147 2.10308 10.2492C2 10.3838 2 10.6003 2 11.0332V12.9669C2 13.3998 2 13.6163 2.10308 13.7508C2.20615 13.8854 2.46711 13.9558 2.98902 14.0965C4.9394 14.6225 6.16008 16.6616 5.65672 18.5992C5.51829 19.1321 5.44907 19.3985 5.51516 19.5548C5.58126 19.7111 5.77092 19.8188 6.15025 20.0341L7.87495 21.0134C8.24721 21.2247 8.43334 21.3304 8.6004 21.3079C8.76746 21.2854 8.95588 21.0973 9.33271 20.7213C10.7927 19.2644 13.2088 19.2643 14.6689 20.7212C15.0457 21.0973 15.2341 21.2853 15.4012 21.3078C15.5682 21.3303 15.7544 21.2246 16.1266 21.0133L17.8513 20.034C18.2307 19.8187 18.4204 19.711 18.4864 19.5547C18.5525 19.3984 18.4833 19.132 18.3448 18.5991C17.8412 16.6616 19.0609 14.6226 21.011 14.0965Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
            </svg>
          </div>
          <div
            onClick={() => {
              setTumKul(!tumKul);
            }}
            className="flex-1 py-4 cursor-pointer w-full hover:bg-zinc-900 active:scale-105 bg-zinc-800 rounded-xl flex px-5 items-center justify-between"
          >
            <p className="text-2xl font-extrabold text-white">
              Tüm Kullanıcılar
            </p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width={80}
              height={80}
              color={"white"}
              fill={"none"}
            >
              <path
                d="M2 7.5H21"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinejoin="round"
              />
              <path
                d="M16.5 2.5L13.5 7.5"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinejoin="round"
              />
              <path
                d="M9.5 2.5L6.5 7.5"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinejoin="round"
              />
              <path
                d="M11.5 21C7.02166 21 4.78249 21 3.39124 19.6088C2 18.2175 2 15.9783 2 11.5C2 7.02166 2 4.78249 3.39124 3.39124C4.78249 2 7.02166 2 11.5 2C15.9783 2 18.2175 2 19.6088 3.39124C21 4.78249 21 7.02166 21 11.5"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
              <path
                d="M14 18H22"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
            </svg>
          </div>
        </div>
        <div className="flex items-center justify-between 2xl:px-96 px-5">
          <div
            onClick={() => {
              setModalAcik(true);
              setCSVModal(true);
            }}
            className="cursor-pointer w-full flex-1 mt-2 py-4 hover:bg-green-500 active:scale-105 bg-emerald-500 rounded-xl flex px-5 items-center justify-between"
          >
            <p className="text-2xl font-extrabold text-white">
              CSV ile TOPLU Oluştur
            </p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width={80}
              height={80}
              color={"white"}
              fill={"none"}
            >
              <path
                d="M2.9668 10.002V15.002C2.9668 17.8304 2.9668 19.2446 3.84548 20.1233C4.72416 21.002 6.13837 21.002 8.9668 21.002H12.4668M20.9668 10.002V11.502"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
              <path
                d="M14.9668 18.502H21.9668M18.4668 22.002V15.002"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
              <path
                d="M6.9668 17.002H10.9668"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
              <path
                d="M10.1038 7.92324C9.82182 8.94288 8.79628 10.7018 6.84777 10.9567C5.12733 11.1817 3.82246 10.4299 3.48916 10.1155C3.12168 9.86056 2.28416 9.04485 2.07906 8.53503C1.87395 8.02521 2.11324 6.9206 2.28416 6.47026L2.96743 4.48946C3.13423 3.99194 3.5247 2.81521 3.92501 2.4172C4.32533 2.01918 5.13581 2.00187 5.4694 2.00187H12.4749C14.2781 2.02737 18.2209 1.98577 19.0003 2.00187C19.7797 2.01797 20.2481 2.67211 20.3848 2.95251C21.5477 5.77276 22 7.38793 22 8.07619C21.8482 8.8104 21.22 10.1948 19.0003 10.8037C16.6933 11.4366 15.3854 10.2057 14.9751 9.7331M9.15522 9.7331C9.47997 10.1325 10.4987 10.9363 11.9754 10.9567C13.4522 10.9771 14.7273 9.94553 15.1802 9.42721C15.3084 9.27426 15.5853 8.82053 15.8725 7.92324"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>

        {kulKontrol && <KKontrol />}
        {tumKul && <TumKullanici />}
      </div>
      {altKulOlusturModal && <KullaniciEkle />}
      {csvModal && <CSVModal />}
      {modalAcik && (
        <button
          onClick={handleSifirla}
          className="fixed z-50 active:scale-105 bg-red-500 bottom-28 transform translate-x-1/2 right-1/2 py-2 w-80 text-white font-bold text-lg rounded-md"
        >
          Kapat
        </button>
      )}
    </div>
  );
};

export default Panel;
